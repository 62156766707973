import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageName, StoreMode } from 'src/app/core/enums';
import { UserService } from 'src/app/core/user/user.service';
import { UserQuery } from "src/app/core/user/user.query";
import { ConfigService } from 'src/app/config.service';
import { environment } from 'src/environments/environment';
import { QrScannerService } from 'src/app/core/services/qr-scanner.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserChnlQuery } from 'src/app/home/userchnl/userchnl.query';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-profile-nav-mobile',
  templateUrl: './profile-nav-mobile.component.html',
  styleUrls: ['./profile-nav-mobile.component.scss']
})
export class ProfileNavMobileComponent implements OnInit {

  curUser: any;
  sub: Subscription = new Subscription();
  isLoggedIn: boolean = undefined;
  user$: Subscription;

  pageNames: any = PageName;
  mailText: string = '';
  encryptedCustID: any;
  name: any;
  mobileNo: any;
  phoneNumber: string = '';
  passwordFlag: boolean = false;

  buildVersion: string = environment.version;
  userChnlSub: Subscription = new Subscription();
  isDefaultDomain: boolean = false;
  channelId : number;

  constructor(
    private router: Router,
    private userService: UserService,
    private userQuery: UserQuery,
    private configService: ConfigService,
    private qrScannerService: QrScannerService,
    private userchnlQry: UserChnlQuery,
    private channelQuery : ChannelQuery,
    private themeService: ThemeService,
  ) {
    this.user$ = this.userQuery.isLoggedIn$.subscribe(userData => {
      this.isLoggedIn = userData && userData.id != 999 ? true : false;
    });
  }

  async ngOnInit(): Promise<void> {
    this.themeService.checkHrefToGetChannelTag();

    this.channelQuery.selectFirst().subscribe(channel => {
      if(channel){
        this.channelId = channel.channelId;
      }
    });

    this.sub = this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.curUser = dt ? dt : null;
    });

    this.userChnlSub = this.userchnlQry.isSubDomain$.subscribe((data) => {
      this.isDefaultDomain = !data;
    });

    this.encryptedCustID = this.curUser ? this.curUser.encryptedCustID : null;
    this.name = this.curUser ? this.curUser.name : null;
    this.mobileNo = this.curUser ? this.curUser.mobileNo : null;

    if (this.mobileNo != null) {
      this.phoneNumber = "+" + this.mobileNo.substring(0, 2) + "*****" + this.mobileNo.substring(this.mobileNo.length - 4);
    }

    if(this.isLoggedIn) {
      let resp: any = await this.userService.getCustomerProfileStatus(this.mobileNo, "");
      this.passwordFlag = !(resp instanceof HttpErrorResponse)? resp.passwordFlag: this.passwordFlag;
    }
  }

  onLogin() {
    this.router.navigateByUrl('/login', { state: { isFromHomePage: true, showContGuest: true } });
  }

  async onLogout() {
    await this.userService.logoutInit(false);
  }

  onNavigate(dt: string) {
    const originCountryUrl = this.isDefaultDomain ? (window.location.origin + this.configService.get('appBaseHref')) : window.location.origin;

    switch (dt) {
      case 'MngAddr': {
        this.router.navigate(["account", "address"]);
        break;
      }
      case 'editProfile': {
        this.router.navigate(["account", "profile"]);
        break;
      }
      case 'fav': {
        this.router.navigateByUrl("/favourite-store");
        break;
      }
      case 'setupPw': {
        this.router.navigate(["account", "setup-password"], {state: { isProfile: true}});
        break;
      }
      case 'changePw': {
        this.router.navigate(["account", "change-password"], {state: { pageName: this.pageNames.ResetPasswordPage }});
        break;
      }
      case 'paymentMtd': {
        this.router.navigateByUrl("/paymentMethod");
        break;
      }
      case 'RefFrd': {
        this.router.navigateByUrl("/referral");
        break;
      }
      case 'emailus': {
        this.mailText = "mailto:customerservice@odaring.com?subject=Order Issue";
        window.location.href = this.mailText;
        break;
      }
      case 'faq': {
        window.open(originCountryUrl + '/faq', '_blank');
        break;
      }
      case 'privacyPolicy': {
        window.open(originCountryUrl + '/privacy-policy', '_blank');
        break;
      }
      case 'tnc': {
        window.open(originCountryUrl + '/term-conditions', '_blank');
        break;
      }
      case 'setting': {
        this.router.navigateByUrl("/settings");
        break;
      }
      default: {
        this.router.navigateByUrl("/");
        break;
      }
    }
  }

  showQRDialog() {
    this.userService.showQrPopup();
  }

  showScannerDialog() {
    this.qrScannerService.show(true, false);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.userChnlSub?.unsubscribe();
  }
}
