
<div class="refund-return-form-container" *ngIf="orderTranId">
  <section class="refund-return-form-header hidden-sm">
    <div class="refund-return-form-header-wrapper p-d-flex p-jc-center">
      <div class="p-mr-6 sub-content">
        <h1 class="heading-3">{{ 'refund.return.email.header.2' | translate }}</h1>
        <p class="email-header-heading-2">{{ 'refund.return.email.title.1' | translate }}</p>
      </div>
      <div class="mascot-container"><img src="assets/mascot/Mascot_sad@2x.webp" alt="odaring mascot"/></div>
    </div>
  </section>

  <section class="refund-return-form-header-mobile hidden-lg">
    <div class="top p-d-flex p-jc-center">
      <div><app-back-button [color]="'var(--backbutton)'"></app-back-button></div>
      <h1 class="heading-7">{{ 'refund.return.email.header.2' | translate }}</h1>
    </div>
    <div>
      <div class="bottom p-d-flex p-jc-center">
        <img src="assets/mascot/Mascot_sad@2x.webp" alt="odaring mascot"/>
      </div>
      <p class="email-header-heading-2">{{ 'refund.return.email.title.1' | translate }}</p>
    </div>
  </section>

  <section class="refund-return-form">
    <h2 class="heading-4 p-text-center hidden-sm">{{'refund.return.email.header.1' | translate}}</h2>
    <div class="refund-return-form-wrapper p-fluid">
      <form [formGroup]="refundForm" (ngSubmit)="onSubmit()">
        <!--email-->
        <div class="p-field">
          <label for="email" required>{{ 'input.label.8' | translate }}</label>
          <input type="text" id="email" formControlName="email" email pInputText placeholder="{{ 'input.placeholder.8' | translate }}" #email />
          <div class="error">
            <div *ngIf="f.email.errors?.required"><i class="oda-alert"></i>{{ 'refund.return.email.error.required' | translate }}</div>
            <div *ngIf="f.email.errors?.email"><i class="oda-alert"></i>{{ 'refund.return.email.error.email.invalid' | translate }}</div>
          </div>
        </div>

        <!--message-->
        <div class="p-field">
          <label for="message" required>{{ 'refund.return.history.title.1' | translate }}</label>
          <textarea rows="4" cols="30" id="message" formControlName="message" pInputTextarea [autoResize]=true placeholder="{{ 'input.placeholder.12' | translate }}" #message></textarea>
          <div class="error">
            <div *ngIf="f.message.errors?.required"><i class="oda-alert"></i>{{ 'refund.return.email.error.required' | translate }}</div>
          </div>
        </div>

        <!--upload image-->
        <div class="p-field">
          <div class="upload-image-container">
            <!--upload image button-->
            <button class="btn-upload-img heading-10" type="button" (click)="fileInput.click()" [disabled]="curTotalImage >= maxImage" [class.uploaded]="curTotalImage > 0">
              <i class="oda-c-pictures"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                {{ 'input.label.13' | translate }} <span>({{ 'refund.return.email.max3' | translate }})</span>
            </button>
            <!-- upload restriction -->
            <p class="restriction">{{ 'refund.return.email.upload.restriction' | translate }}</p>
            <!--preview image-->
            <div class="preview-img-container d-flex p-mr-6" *ngIf="images.length">
              <div class="preview-img-wrapper" *ngFor="let image of images; let i = index">
                <div id="imagePreview" [style.backgroundImage]="'url('+ image +')'"></div>
                <i class="oda-times" (click)="onRemoveImage(i)"></i>
              </div>
            </div>
            <input type='file' id="input-upload-img" formControlName="image" multiple accept=".gif,.png,.jpg,.jpeg,.bmp" #fileInput (change)="onImageChange($event)" />
          </div>
          <div class="error" [style.display]="isMaxSizeError ? 'block': 'none'">
            <div *ngIf="isMaxSizeError"><i class="oda-alert"></i>{{ 'refund.return.email.error.restriction' | translate }}</div>
          </div>
        </div>
        <div class="bottom-btn-container">
          <button type="submit" class="email-btn-submit heading-10" [disabled]="!refundForm.valid">{{ 'button.submit' | translate }}</button>
        </div>
      </form>
    </div>
  </section>
  <ng-template #requestedPage>
    <div class="refund-return-requested-container">
      <div class="refund-return-requested-wrapper">
        <div class="img-container">
          <img src="assets/mascot/Mascot_holding-cutlery@2x.webp" alt="odaring mascot"/>
        </div>
        <p>{{ 'refund.return.email.success.title.1' | translate }}</p>
        <a type="button" class="btn-back-to-history" [routerLink]="['/my-order']" >{{ 'button.back.to.history' | translate }}</a>
      </div>
    </div>
  </ng-template>
</div>
