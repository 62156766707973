export const environment = {
  version: 'v1.6.98',
  production: true,
  hostPath: 'https://odafrontapi100.odaring.com',
  secretKey: 'Yuz7ZoQrgRRk14NKKdgzzKXtL95rmzua5sVuq+Vdvdg=',
  apis: {
    user: {
      Profile: '/api/customer/getcustomerprofile',
      EncryptedProfile: '/api/customer/getcustomerprofilebyencrypteddata',
      UpdateProfile: '/api/customer/updatecustomerprofile',
      Login: '/api/customer/web/login',
      Logout: '/api/customer/logout',
      Register: '/api/customer/registercustomer',
      UpdateNotifyToken: '/api/customer/updatenotifytoken',
      UpdatePassword: '/api/customer/updatepassword',
      UpdateMobileStatus: '/api/customer/updatemobilenostatus',
      SendOTP: '/api/customer/sendotp',
      VerifyOTP: '/api/customer/verifyotp',
      ConfirmEmailStatus: '/api/customer/confirmemailstatus',
      RefreshToken: '/api/customer/web/refreshtoken',
      VerifyUpdateMobileNo: '/api/customer/verifyupdatemobileno',
      SendEmailVerification: '/api/customer/sendemailverification',
      ForgotPassword: '/api/customer/resetpassword',
      RegisterCustomerAddress: '/api/customer/registercustomeraddress',
      UpdateCustomerAddress: '/api/customer/updatecustomeraddress',
      DeleteCustomerAddress: '/api/customer/deletecustomeraddress',
      GetCustomerAddress: '/api/customer/getcustomeraddress',
      GetCustomerSavedCard: '/api/customer/getcustomersavedcard',
      DeleteCustomerSavedCard: '/api/customer/removecustomersavedcard',
      GetFavouriteStore: '/api/customer/getfavouritestore',
      DeleteFavouriteStore: '/api/customer/removefavouritestore',
      AddFavouriteStore : '/api/customer/addfavouritestore',
      UpdateChatId: '/api/customer/updatechatid',
      DeleteCustomer: '/api/customer/deletecustomer',
      GetCustomerProfileStatus: '/api/customer/getcustomerprofilestatus'
    },
    channel: {
      GetChannelData: '/api/channel/getchanneldata',
      GetChannelHomeSection: '/api/channel/getchannelhomesection',
      SwitchChannel: '/api/channel/switchchannel',
      GetCustomerChannel: '/api/channel/getcustomerchannel',
      DeleteCustomerChannel: '/api/channel/deletecustomerchannel',
      PlaceAutoComplete: '/api/channel/placeautocomplete',
      GetPlaceDetails: '/api/channel/getplacedetails',
      GetlocationAddress: '/api/channel/getlocationaddress'
    },
    merchant: {
      GetOrderMenu: '/api/external/general/getordermenu'
    },
    order: {
      GetActivitySummary: '/api/order/getactivitysummary',
      GetActivityDetail: '/api/order/getactivitydetail',
      GetOrderSummary: '/api/order/getordersummary',
      GetOrderDataHistory: '/api/order/getorderdatahistory',
      CheckoutOrder: '/api/order/checkoutorder',
      AcceptOrder: '/api/order/acceptOrder',
      RequestRefundOrder: '/api/order/requestrefundorder',
      RequestCancelOrder: '/api/order/requestcancelorder',
      ReceiptRequest: '/api/order/receiptrequest',
      SubmitOrder: '/api/order/submitorder',
      GetActivityByLinkId: '/api/order/getactivitybylinkid',
      ConfirmPickupOrder: '/api/order/confirmpickuporder',
      UpdateCustomer: '/api/order/updatecustomer',
      GetActivityByOrders: '/api/order/getactivitybyorders',
      RevertCutOffOrder: '/api/order/revertcutofforder',
      GetActivityByRsvTokenId: '/api/order/getactivitybyrsvtokenid',
      GetActivityByActivityGuid: '/api/order/getactivitybyactivityguid',
      OrderPaymentSafetyCheck: '/api/order/orderpaymentsafetycheck'
    },
    store: {
      GetOrderMenu: '/api/external/general/getordermenu',
      GetOutlets: '/api/store/getoutlets',
      GetStoreMenuData: '/api/store/getstoremenudata',
      GetStore: '/api/store/getstore',
      GetStores: '/api/store/getstores',
      GetStoreForQueue: '/api/store/getstoreforqueue'
    },
    cart: {
      RecalculateCart: '/api/cart/recalculatecart',
      GetCustomerCart: '/api/cart/getcustomercart',
    },
    review: {
      UpdateStoreReview: '/api/review/updatestorereview',
      GetChannelReviewText: '/api/review/getchannelreviewtext',
      GetStoreReviewData: '/api/review/getstorereviewdata',
    },
    webLink: {
      validateweblinktoken: '/api/weblink/validateweblinktoken',
    },
    voucher: {
      GetVoucherTypeByVchTypCode: '/api/voucher/getvouchertypebyvchtypcode',
      GetVoucherTypeByVchTypId: '/api/voucher/getvouchertypebyvchtypid',
      GetStoreVoucherType: '/api/voucher/getstorevouchertype',
      GetNearbyVoucherType: '/api/voucher/getnearbyvouchertype',
      GetVoucherTypeCategories: '/api/voucher/getvouchertypecategories',
      ClaimVoucher: '/api/voucher/claimvoucher',
      GetCustomerClaimedVoucher: '/api/voucher/getcustomerclaimedvoucher',
      GetApplicableVoucher: '/api/voucher/getapplicablevoucher',
      GetCustomerVoucherHistory: '/api/voucher/getcustomervoucherhistory',
      Redeemmembershipvoucher: '/api/voucher/redeemmembershipvoucher',
      Getmemberclaimedvoucher: '/api/voucher/getmemberclaimedvoucher',
    },
    queue: {
      GetOrJoinQueue: '/api/queue/getorjoinqueue',
      GetQueueByToken: '/api/queue/getqueuebytoken',
      UpdateQueueInfo: '/api/queue/updatequeueinfo',
      CancelQueue: '/api/queue/cancelqueue',
      AcknowledgeQueue: '/api/queue/acknowledgequeue'
    },
    infoPanel: {
      GetInfoPanelData: '/api/infopanel/getinfopaneldata'
    },
    membership: {
      GetMembershipTermsData: '/api/membership/getmembershiptermsdata',
      JoinMembership: '/api/membership/joinmembership',
      QuitMembership: '/api/membership/quitmembership',
      GetMembershipDetails: '/api/membership/getmembershipdetails',
      GetCustomerMemberships: '/api/membership/getcustomermemberships',
      GetMembershipRewardHistory: '/api/membership/getmembershiprewardhistory',
      Getmembershipbylogin: '/api/membership/getmembershipbylogin',
    },
    appLink: {
      GetAppLinkToken: '/api/applink/getapplinktoken'
    }
  },
  localGenCustomerId: 999,
  optExpireInSec: 60,
  optPrefix: 'htwt',
  optMaxFailBypass: 3,
  optResendInSec: 60,
  optMobileCharNumShow: 4,
  pwdResetRedirectInSec: 5,
  hostName: 'odaring',
  odaringChannel: 'MY',
  subscription: '00001',
  firebase: {
    apiKey: "AIzaSyD7RxpXSTSTiXM3XjHCpdeBxtiYUuYNltc",
    authDomain: "odaringprod.firebaseapp.com",
    projectId: "odaringprod",
    storageBucket: "odaringprod.appspot.com",
    messagingSenderId: "320965753708",
    appId: "1:320965753708:web:0edf0d3898540d5c0e9149",
    measurementId: "G-JSHFPF680Y"
  },
  enableDineIn: true,
  shareRewardDomain: "https://www.odaring.com/",
  guestMobileNo: "0111111111",
  guestPassword: "123123123",
  timeToRemove: 2,
  origin: 'https://www.odaring.com/',
  enableShareVch: false,
  appInsights: {
    instrumentationKey: ''
  }
};
