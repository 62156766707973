<div class="qr-dinein-selection">
  <p-dialog [visible]="true" [position]="dialogPosition" [dismissableMask]="dismissable" [draggable]="false"
    [style]="{width:'650px'}" [baseZIndex]="10000" [modal]="true" [closable]='true'
    [ngClass]="{'seeMoreDialog' : isRemarkExpand}">

    <ng-template pTemplate="content" *ngIf="(qrTokenResponse && (storeResponse.qrDineFlag || storeResponse.qrTakeawayFlag)) || (!qrTokenResponse)">
      <div class="overflow-container" id="qr-overflow-container">
        <div class="qr-dinein-header">
          <i class="oda-table-dinein"></i>
          <!-- table number without table label given -->
          <span class="table-no" *ngIf="!tableLabel">{{"merchant.qr.dinein.table.no" | translate : {tableNo: tableNo} }}</span>
          <!-- table number with table label given -->
          <span class="table-no" *ngIf="tableLabel">{{ tableLabel + ' ' + tableNo}}</span>

          <div class="view-order-container" (click)="onClickViewOrder()" *ngIf="detailActivities && detailActivities?.length > 0">
            <i class="oda-loading"></i>
            <span class="view-order-description">{{"qr.dinein.myorders" | translate}} ({{detailActivities.length}})</span>
          </div>
        </div>

        <!--store remark-->
        <div class="storeRemark-container"
          *ngIf="storeResponse.platformSets | platformSetCPSR : setCode.STO_ODR_REMARK : storeResponse.storeId">
          <div class="remark-container"  #target>
            <div class="remark-hideScroll" [ngClass]="{'remark-innerScroll' : isRemarkExpand, 'remark-overflow': seeMore}">
              <div class="storeRemark-header" id="target">
                <i class="oda-store-announce"></i>
                <h1 *ngIf="storeResponse.platformSets | platformSetCPSR : setCode.STO_ODR_RMK_TTL : storeResponse.storeId; else elseBlock"
                  [innerHTML]="(storeResponse.platformSets | platformSetCPSR : setCode.STO_ODR_RMK_TTL : storeResponse.storeId).setValue">
                </h1>
                <ng-template #elseBlock>
                  <h1>{{'Welcome.to' | translate: {storeName: storeResponse.locDesc} }}</h1>
                </ng-template>
              </div>
              <div class="storeRemark-content" [innerHTML]="(storeResponse.platformSets | platformSetCPSR : setCode.STO_ODR_REMARK : storeResponse.storeId).setValue"></div>

              <h1 *ngIf="isRemarkExpand" class="readMoreLess-btn" (click)="onRemarkExpand()">{{'read.less' | translate}}</h1>
            </div>
            <h1 *ngIf="seeMore && !isRemarkExpand" class="readMoreLess-btn" (click)="onRemarkExpand()">{{'read.more' | translate}}</h1>
          </div>
        </div>

        <!-- slide show for banner image -->
        <div class="slide-show-container" [style.min-height]="bannerImg.length > 1 ? '180px' : '160px'" *ngIf="bannerImg && bannerImg?.length >= 1">
          <swiper
          [pagination]="bannerImg.length > 1 ? true : false"
          [autoplay]="{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }"
        class="mySwiper">
            <ng-template swiperSlide *ngFor="let image of bannerImg"><img [src]="image.attrData" alt="odaring image" loading="lazy"></ng-template>
          </swiper>
        </div>

        <div class="qr-order-type-selection">
          <!-- dynamic QR dine in QR dine flag OR static dine in dine flag false = disable, otherwise enable -->
          <div class="order-type" [ngClass]="{'selected-order': currentQrOrderType == orderType.DineIn,
        'order-type-disable': (!storeResponse.qrDineFlag && qrTokenResponse) || (!storeResponse.dineFlag && !qrTokenResponse),
        'order-type-enable': (storeResponse.qrDineFlag && qrTokenResponse) || (storeResponse.dineFlag && !qrTokenResponse)}"
            (click)="changeQrOrderType(orderType.DineIn)" *ngIf="(channelId | isShowMascot)">
            <span class="dine-in-title">{{ dineLabel | translate}}</span>
            <img src="assets/mascot/Mascot_dine-in@2x.webp" *ngIf="(storeResponse.qrDineFlag && qrTokenResponse) || (storeResponse.dineFlag && !qrTokenResponse)" alt="odaring image">
            <img src="assets/mascot/Mascot_dine-in-Grey@2x.webp" *ngIf="(!storeResponse.qrDineFlag && qrTokenResponse) || (!storeResponse.dineFlag && !qrTokenResponse)" alt="odaring image">
          </div>

          <div class="order-type order-type-without-mascot" *ngIf="!(channelId | isShowMascot)"
          [ngClass]="{'selected-order-without-mascot': currentQrOrderType == orderType.DineIn,
                      'order-type-disable': (!storeResponse.qrDineFlag && qrTokenResponse) || (!storeResponse.dineFlag && !qrTokenResponse),
                      'order-type-enable': (storeResponse.qrDineFlag && qrTokenResponse) || (storeResponse.dineFlag && !qrTokenResponse)}"
            (click)="changeQrOrderType(orderType.DineIn)" >
            <i class="oda-dinein"></i>
            <span class="dine-in-title">{{ dineLabel | translate}}</span>
          </div>

          <!-- dynamic QR dine in QR takeaway flag OR static dine in pickup flag false = disable, otherwise enable -->
          <div class="order-type" [ngClass]="{'selected-order': currentQrOrderType == orderType.Pickup,
        'order-type-disable': (!storeResponse.qrTakeawayFlag && qrTokenResponse) || (!storeResponse.pickupFlag && !qrTokenResponse),
        'order-type-enable': (storeResponse.qrTakeawayFlag && qrTokenResponse) || (storeResponse.pickupFlag && !qrTokenResponse)}"
            (click)="changeQrOrderType(orderType.Pickup)" *ngIf="channelId | isShowMascot">
            <!-- dynamic QR takeaway will display takeaway  -->
            <span class="dine-in-title" *ngIf="qrTokenResponse">{{takeawayLabel | translate}}</span>
            <!-- static QR pickup will display pickup -->
            <span class="dine-in-title" *ngIf="!qrTokenResponse">{{pickupLabel | translate}}</span>
            <img src="assets/mascot/Mascot_take-away@2x.webp" *ngIf="(storeResponse.qrTakeawayFlag && qrTokenResponse) || (storeResponse.pickupFlag && !qrTokenResponse)" alt="odaring mascot">
            <img src="assets/mascot/Mascot_take-away-Grey@2x.webp" *ngIf="(!storeResponse.qrTakeawayFlag && qrTokenResponse) || (!storeResponse.pickupFlag && !qrTokenResponse)" alt="odaring mascot">
          </div>

          <div class="order-type order-type-without-mascot" [ngClass]="{'selected-order-without-mascot': currentQrOrderType == orderType.Pickup,
        'order-type-disable': (!storeResponse.qrTakeawayFlag && qrTokenResponse) || (!storeResponse.pickupFlag && !qrTokenResponse),
        'order-type-enable': (storeResponse.qrTakeawayFlag && qrTokenResponse) || (storeResponse.pickupFlag && !qrTokenResponse)}"
            (click)="changeQrOrderType(orderType.Pickup)" *ngIf="!(channelId | isShowMascot)">
            <i class="oda-bag"></i>
            <!-- dynamic QR takeaway will display takeaway  -->
            <span class="dine-in-title" *ngIf="qrTokenResponse">{{takeawayLabel | translate}}</span>
            <!-- static QR pickup will display pickup -->
            <span class="dine-in-title" *ngIf="!qrTokenResponse">{{pickupLabel | translate}}</span>
          </div>

        </div>

        <div class="no-of-pax-container">
          <span class="heading-11">{{"merchant.no.of.pax.title" | translate}}</span>
          <div class="pax-indicator-container p-d-flex">
            <span class="minus-button" [style.border]="guestCount > 1 ? '1px solid var(--button-primary)' : '1px solid #F4F4F4'" (click)="minusPax()">
              <i class="oda-minus" [style.color]="guestCount > 1 ? 'var(--button-primary)' : '#B1B1B1' "></i>
            </span>
            <span><p class="pax-no heading-11">{{guestCount}}</p></span>
            <span class="plus-button" (click)="plusPax()"><i class="oda-plus"></i></span>
          </div>
        </div>

        <!-- reference container will only be shown when setting permit and it is dynamic qr dine in -->
        <div class="ref-no-container" *ngIf="(storeResponse.platformSets | platformSetCPSR : setCode.REFNOLABEL : storeResponse.storeId) && qrTokenResponse">
          <label class="ref-label">
            {{(storeResponse.platformSets | platformSetCPSR : setCode.REFNOLABEL : storeResponse.storeId).setValue}}
            <span style="color: var(--iconTextButton-primary)" *ngIf="(storeResponse.platformSets | platformSetCPSR : setCode.ASKREFNO : storeResponse.storeId)?.setValue == '1'">
              &#42;
            </span>
          </label>
          <input class="ref-no-input" [(ngModel)]="refNoInput" maxlength="25" (focus)="onRefInputFocus()">

          <!-- required message for reference number input box -->
          <span class="required-message-container" *ngIf="requiredRefNo">
            <i class="oda-alert"></i>
            <p class="required-message">{{"qr.ref.no.required" | translate}}</p>
          </span>
        </div>

        <!-- phone number section will only be shown when not logged in and is dynamic qr dine in -->
        <div class="phone-no-section" *ngIf="mobileNumberMandatory">
          <form [formGroup]="fgroup">
            <div class="phone-no-container" id="phoneContainer">
              <div class="phone-no-title-container p-d-flex">
                <span class="phone-no-title">{{"qr.phone.submit.title.1" | translate}}</span>
              </div>

              <div class="phone-input-box-container">
                <div class="phone-input-box">
                  <ngx-intl-tel-input
                  [cssClass]="'custom'"
                    [enableAutoCountrySelect]="telInpSetting.enableAutoCountrySelect"
                    [customPlaceholder]="telInpSetting.customPlaceholder | translate" [enablePlaceholder]="telInpSetting.enablePlaceholder"
                    [searchCountryFlag]="telInpSetting.searchCountryFlag" [searchCountryField]="telInpSetting.searchCountryField"
                    [selectFirstCountry]="telInpSetting.selectFirstCountry"
                    [phoneValidation]="telInpSetting.phoneValidation" [inputId]="'phoneNo'"
                    [separateDialCode]="telInpSetting.separateDialCode" [preferredCountries]="telInpSetting.preferredCountries"
                    [selectedCountryISO]="telInpSetting.selectedCountryISO" name="phone" formControlName="phoneNo">
                  </ngx-intl-tel-input>
                  <button type="reset" class="clear-input" (mousedown)="$event.preventDefault();"><i class="oda-times" *ngIf="phoneNoVal && isFocus"></i></button>
                </div>

                <!-- invalid mobile number message -->
                <div class="invalid-feeback" *ngIf="isInvalidMobileNumber">
                  <i class="oda-alert"></i>
                  {{ 'login.form.mobile.number.invalid' | translate }}
                </div>

                <!-- mobile number required message -->
                <div class="invalid-feeback" *ngIf="mobileNumberNeeded">
                  <i class="oda-alert"></i>
                  {{ 'qr.mobile.number.required' | translate }}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>

    <!-- content template which will only be shown during dynamic QR Dine in when both takeaway and dine in flag are false -->
    <ng-template pTemplate="content" *ngIf="!storeResponse.qrDineFlag && !storeResponse.qrTakeawayFlag && qrTokenResponse">
      <div class="qr-dinein-header">
        <i class="oda-table-dinein"></i>
        <span class="table-no" *ngIf="!tableLabel">{{"merchant.qr.dinein.table.no" | translate : {tableNo: tableNo} }}</span>
        <span class="table-no" *ngIf="tableLabel">{{ tableLabel + ' ' + tableNo }}</span>

        <div class="view-order-container" (click)="onClickViewOrder()" *ngIf="detailActivities && detailActivities?.length > 0">
          <i class="oda-loading"></i>
          <span class="view-order-description">{{"qr.dinein.myorders" | translate}} ({{detailActivities.length}})</span>
        </div>
      </div>

      <div class="mascot-unavailable-container" *ngIf="channelId | isShowMascot">
        <img src="assets/mascot/Mascot_qr-dine-in-disable-Grey@2x.webp" alt="odaring mascot">
      </div>

      <h3 class="title-description heading-11">{{"alert.qr.dinein.disabled.desc.1" | translate}}</h3>

      <p class="description heading-9">{{"alert.qr.dinein.disabled.desc.2" | translate}}</p>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="action-button-container" *ngIf="(qrTokenResponse && (storeResponse.qrDineFlag || storeResponse.qrTakeawayFlag)) || (!qrTokenResponse)">
      <button class="qr-dinein-action-button" [ngClass]="{
        'not-fulfill': (!refNoInput && refCompulsory) || (mobileNumberMandatory && f.phoneNo.value && f.phoneNo.invalid && !isLoggedIn) || (mobileNumberMandatory && !f.phoneNo.value && !isLoggedIn)}"
        (click)="onClickContinue()">
          {{"button.continue" | translate}}
        </button>
      </div>

      <button class="order-unavailable-button" (click)="closeDineInPopup()" *ngIf="!storeResponse.qrDineFlag && !storeResponse.qrTakeawayFlag && qrTokenResponse">
        {{"merchant.reorder.button.desc.1" | translate}}
      </button>
    </ng-template>

  </p-dialog>
</div>


