import { StoreService } from 'src/app/store/store/store.service';
import { QueueService } from './../../../queue/queue/queue.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageName } from 'src/app/core/enums';
import { LoginRequest } from 'src/app/core/models/LoginRequest';
import { AuthService } from 'src/app/core/services/auth.service';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { OtpService } from '../../services/otp.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/core/user/user.service';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-password-login',
  templateUrl: './password-login.component.html',
  styleUrls: ['./password-login.component.scss']
})
export class PasswordLoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("password", { static: false }) passwordEl: ElementRef;
  telInpSetting = DefaultSettingService.telInpSetting;

  data: any = null;
  phoneNo: string = "";
  phoneNoExist: boolean = true;
  showPw: boolean = false;
  pwErr: HttpErrorResponse = null;
  isQueue : boolean = false;
  navigateToPayment : boolean = false;
  showContinueAsGuest : boolean = true;
  isSubmittedLogin : boolean = false;
  countryCode : string = "MY";
  doNotRemoveContinueAsGuest : boolean = false;
  isFromVoucher : boolean = false;

  fgroup: UntypedFormGroup = this.fb.group({
    phoneNo: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(8)]]
  });

  constructor(private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private otpService: OtpService,
    private queueService : QueueService,
    private storeService : StoreService,
    private location : Location,
    private userService : UserService,
    private previousRouteService : PreviousRouteService,
    private utilsService : UtilsService,
    private sessionStorageService: SessionStorageService,
    private themeService: ThemeService,
  ) { }

  get fg() { return this.fgroup }

  get f() { return this.fg.controls; }

  async ngOnInit() {
    this.themeService.checkHrefToGetChannelTag();
    let queueData = this.queueService.getQueueData();
    this.isQueue = queueData ? true : false;
    this.data = history.state.data? history.state.data: null;

    // will be set in both cart and order summary to determine whether to navigate to payment page after login
    let cachePaymentFlag = JSON.parse(this.sessionStorageService.getItem("navigateToPayment"));
    this.navigateToPayment = cachePaymentFlag? cachePaymentFlag.toPayment : false;

    let fromVoucher =JSON.parse(this.sessionStorageService.getItem("fromVoucher"));
    this.isFromVoucher = fromVoucher ? fromVoucher : false;

    this.showContinueAsGuest = this.userService.getShowContinueAsGuestFlag();
    this.isSubmittedLogin = this.authService.getSubmittedLogin();

    // if data pass in is remove and is queue then navigate to pre queue
    // else if no data, not queue and guest payment then back
    // else just move to login page
    if(!this.data && this.isQueue){
      await this.queueService.navigateToPreQueue();
    }
    else if(!this.data && !this.isQueue && this.navigateToPayment){
      this.location.back();
    }
    else if(!this.data && !this.isQueue && !this.navigateToPayment){
      this.router.navigateByUrl("/login", { replaceUrl: true });
    }

    this.phoneNoExist = history.state.phoneNoExist? history.state.phoneNoExist: true;
    this.phoneNo = history.state.data? history.state.data['phoneNo']: "";
    this.fg.controls['phoneNo'].setValue(this.phoneNo);
    this.fg.controls['phoneNo'].disable();

    let dialCode = history.state.data? history.state.data['dialCode']: "";
    let parsedPhoneData = await this.utilsService.parseGivenPhoneNumber("+" + dialCode + this.phoneNo);
    this.countryCode = parsedPhoneData.country;
  }

  ngAfterViewInit(): void {
    this.passwordEl.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.authService.removeSubmittedLoginFlag();
    if(!this.doNotRemoveContinueAsGuest)
      this.userService.removeShowContinueAsGuestFlag();
  }

  async onReset() {
    if (this.navigateToPayment && !this.isQueue) {
      let backRoute = JSON.parse(this.sessionStorageService.getItem("isLoginFromCart"));
      if(backRoute){
        this.router.navigateByUrl("/login", { replaceUrl: true });
      }else{
        this.location.back();
      }
    }
    else if(!this.isQueue && !this.navigateToPayment){
      this.router.navigateByUrl("/login", { replaceUrl: true });
    }
    else{
      let queueData = this.queueService.getQueueData();
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(queueData.storeData.locShortDesc);
      this.router.navigate(["queue", queueData.storeData.storeId, locDescWithoutSpaces], { replaceUrl : true });
    }
  }

  async onOtpLogin() {
    this.doNotRemoveContinueAsGuest = true;
    await this.otpService.onSendOTP(this.data.mobileObj);
    this.router.navigate(["otp"], { state: { loginForm: this.data, phoneNoExist: this.phoneNoExist}, replaceUrl: true });
  }

  async onForgotPw() {
    await this.otpService.onSendOTP(this.data.mobileObj, PageName.ForgotPasswordPage);
    this.router.navigate(["otp"], { state: { loginForm: this.data, phoneNoExist: this.phoneNoExist}, replaceUrl: true });
  }

  async onSubmit() {
    this.pwErr = null;

    let loginReq: LoginRequest = {
      mobileNo: this.data.dialCode + this.data.phoneNo,
      password: this.fg.controls['password'].value
    };

    let routeName : any

    if(this.isFromVoucher){
      routeName = this.previousRouteService.getPaymentBackUrl();
    } else {
      routeName = this.navigateToPayment ? 'order-payment' : null;
    }

    this.queueService.removeCanQueueRegisterFlag();

    let successLogin = await this.authService.login(loginReq, routeName, this.isQueue);

    if(successLogin instanceof HttpErrorResponse) {
      this.pwErr = successLogin;
      this.fg.controls['password'].reset();
    }
  }

  onClickContinueAsGuest(){
    if(this.isQueue){
      this.queueService.setCanQueueRegister(false);
      this.router.navigate(['queue/complete-queue-profile'], { replaceUrl: true });
    }else if(this.isFromVoucher) {
      this.onClickBack();
    }
    else if (this.navigateToPayment) {
      let routeName = this.navigateToPayment ? 'order-payment' : null;
      if (routeName) {
        this.router.navigate([routeName], { replaceUrl: true, state: { reinit: true } });
      } else {
        this.backPrevious();
      }
    }
    else {
      this.backPrevious();
    }
  }

  backPrevious(){
      // get previous route from store area and remove after that
      let previousRoute = this.previousRouteService.getPreviousRoute();
      this.previousRouteService.removePreviousRoute();
      // navigate by url and replace url
      this.router.navigateByUrl(previousRoute.routeName, { replaceUrl: true });
  }

  onClickBack(){
    let backRoute = this.previousRouteService.getPaymentBackUrl();
    this.doNotRemoveContinueAsGuest = true;
    if(backRoute){
      this.router.navigateByUrl(backRoute);
    }else {
      this.location.back();
    }
  }
}
