<app-download-app></app-download-app>

<div class="pw-login-container">
  <div class="mobile-header hidden-lg">
    <div><app-back-button [color]="'var(--backbutton)'" [manualBackEvent]="true" (onManualBackEvent)="onClickBack()"></app-back-button></div>
    <h1>{{ "login.register.form.header.title.login" | translate }}</h1>
  </div>
  <div class="content-container">
    <img src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot"/>
    <h1 class="hidden-sm">{{ "login.register.form.header.title.login" | translate }}</h1>
    <h2 *ngIf="!isSubmittedLogin">{{ "login.register.form.header.title.3" | translate }}</h2>
    <h2 *ngIf="isSubmittedLogin">{{"phone.submitted.login.title" | translate}}</h2>
    <form (ngSubmit)="onSubmit()" [formGroup]="fgroup">
      <!-- phone number -->
      <div class="input-field">
        <label for="phoneNo">{{ 'input.label.1' | translate }}</label>
        <div class="inline-btn">
          <ngx-intl-tel-input [enableAutoCountrySelect]="telInpSetting.enableAutoCountrySelect" [cssClass]="'mobile-number-input'"
                              [customPlaceholder]="telInpSetting.customPlaceholder | translate" [enablePlaceholder]="telInpSetting.enablePlaceholder"
                              [searchCountryFlag]="telInpSetting.searchCountryFlag" [searchCountryField]="telInpSetting.searchCountryField"
            [selectFirstCountry]="telInpSetting.selectFirstCountry"
            [phoneValidation]="telInpSetting.phoneValidation" [inputId]="'phoneNo'"
            [separateDialCode]="telInpSetting.separateDialCode" [preferredCountries]="telInpSetting.preferredCountries"
            [selectedCountryISO]="countryCode | telInputCountryCode" name="phone" formControlName="phoneNo">
          </ngx-intl-tel-input>
          <button type="button" class="btn-reset" (click)="onReset();">{{ "button.reset" | translate }}</button>
        </div>
      </div>

      <!-- password -->
      <div class="input-field">
        <div class="passwordLabel-forgotPassword">
          <label for="password">{{ 'input.label.2' | translate }}</label>
          <button type="button" class="forgotPassword-btn" (click)="onForgotPw()">{{"button.forgot.password" | translate }}?</button>
        </div>
        <div class="inline-btn">
          <input id="password" [type]="showPw? 'text': 'password'" pInputText formControlName="password"
            placeholder="{{ 'input.placeholder.1' | translate }}" #password>
          <i class="oda-eye-shut" *ngIf="f.password.value?.length > 0"
            [ngClass]="{'oda-eye-shut': !showPw, 'oda-eye-open': showPw }" (click)="showPw = !showPw"></i>
        </div>
        <div class="error" *ngIf="this.pwErr"><i class="oda-alert"></i><span>{{ "login.form.password.invalid" |
            translate }}</span></div>
      </div>

      <button type="submit" class="btn-login" [disabled]="!fgroup.valid">{{ "button.login" | translate }}</button>
    </form>
    <div class="footer-btn-container">
      <button type="button" class="login-withOtp-btn" (click)="onOtpLogin()">{{ "button.login.with.otp" | translate }}</button>
      <button class="continue-as-guest-btn" (click)="onClickContinueAsGuest()" *ngIf="showContinueAsGuest">
        {{'button.continue.as.guest' | translate}}
      </button>
    </div>
  </div>
</div>
