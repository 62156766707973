import { StaticQrService } from './../../core/static-qr/static-qr.service';
import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location, TitleCasePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { SwiperComponent } from "swiper/angular";
import * as _ from 'lodash';

import { ChannelService } from 'src/app/home/channel/channel.service';
import { ChannelData } from 'src/app/core/models';
import { CatgAttrOptionResponse } from 'src/app/core/models/CatgAttrOptionResponse';
import { VoucherTypeExtraResponse } from 'src/app/core/models/VoucherTypeExtraResponse';
import { UserQuery } from 'src/app/core/user/user.query';
import { CurrencyResponse } from 'src/app/core/models/CurrencyResponse';
import { environment } from 'src/environments/environment';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { CustomerClaimedVoucherResponse } from 'src/app/core/models/CustomerClaimedVoucherResponse';
import { VoucherCatgResponse } from 'src/app/core/models/VoucherCatgResponse';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { VoucherCardType } from 'src/app/core/enums/CardType';
import { VoucherExtra } from 'src/app/core/models/VoucherExtra';
import { VoucherCatgCode } from 'src/app/core/enums/VoucherCatgCode';
import { RewardService } from '../services/reward.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MembershipWithPointStampResponse } from 'src/app/membership/membership/membership-with-point-stamp-response';
import { MembershipService } from 'src/app/membership/membership/membership.service';
import { RewardSectionType } from 'src/app/core/enums/RewardSectionType';
import { CartQuery } from 'src/app/core/cart/cart.query';
import { CartModel } from 'src/app/core/models/CartModel';
import { StoreService } from 'src/app/store/store/store.service';
import { OrderH } from 'src/app/core/models/OrderH';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss'],
})
export class RewardsComponent implements OnInit, OnDestroy {
  @ViewChild("menuContainer", { static: false }) headerEl: ElementRef;
  @ViewChild("menuIcon", { static: false }) menuIconEl: ElementRef;
  @ViewChild("storeHeader", { static: false }) storeHeaderEl: ElementRef;
  @ViewChild("searchBar", { static: false }) searchBarEl: ElementRef;
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;
  @ViewChild("menuSlideLeftIcon", { static: false }) menuSlideLeftIconEl: ElementRef;
  @ViewChild("barcode", { static: false }) barcode?: ElementRef;
  @ViewChild("qrcode", { static: false }) qrcode?: ElementRef;

  channel$: Subscription;
  detailDialog$: Subscription;
  storeDialog$: Subscription;
  shareDialog$: Subscription;
  userLoggedIn$: Subscription;
  qrDialog$: Subscription;

  channelData: ChannelData;
  rewards: VoucherTypeExtraResponse[] = [];
  myRewards: CustomerClaimedVoucherResponse;
  myRewardList: any[] = [];
  myRewardCatgList: any[] = [];
  customCardType: VoucherCardType;
  exploreRewardDetail: VoucherTypeExtraResponse;
  myRewardDetail: VoucherExtra;
  customCardTypeDetail: VoucherCardType;
  myRewardQr: VoucherExtra;
  categories: { id: number, desc: string }[] = [];
  storeData: StoreResponse;
  storeList: StoreResponse[];
  currency: CurrencyResponse;
  orderTypeFlag: OrderTypeFlag;
  rewardSectionType: RewardSectionType;
  memberDetail: MembershipWithPointStampResponse[] = [];

  cardTypes: any = VoucherCardType;
  catgCodes: any = VoucherCatgCode;
  rewardSectionTypes: any = RewardSectionType;

  shareUrl: string;
  shareStoreName: string;
  shareDesc: string;
  searchText: string = '';
  searchedText: string = '';
  latitude: number = 0;
  longitude: number = 0;
  channelId: number;
  skip: number = 0;
  take: number = 10;
  categoryId: number = 0;
  rewardDetailData: any;
  lastScrollTop = 0;
  currStickyStore = 0;
  serverTimeMoment: any;

  isLoggedIn: boolean = false;
  isHeaderSticky: boolean = false;
  isCopied: boolean = false;
  isMobile: boolean = false;
  isOnReload: boolean = true;
  isOnLoad: boolean = false;
  isOnListLoad: boolean = false;
  isOnFirstLoad: boolean = true;
  isMaxLoad: boolean = false;
  isFromShare: boolean = false;
  isBackQRDialog: boolean = false;
  showMobileMenu: boolean = false;
  showDetail: boolean = false;
  showShare: boolean = false;
  showStore: boolean = false;
  showQr: boolean = false;
  showQrZoom: boolean = false;
  swiperStart: boolean = true;

  searchFgroup: UntypedFormGroup = this.fb.group({
    searchText: ['']
  });

  cartModel : CartModel;
  cartSub$ : Subscription;

  constructor(private rewardService: RewardService,
    private channelService: ChannelService,
    private userQuery: UserQuery,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private fb: UntypedFormBuilder,
    private membershipService: MembershipService,
    private cartQuery : CartQuery,
    private storeService : StoreService,
    private staticQrService : StaticQrService) { }

  get searchFg() { return this.searchFgroup }

  get searchF() { return this.searchFgroup.controls; }

  async ngOnInit() {
    this.getScreenSize();

    this.userLoggedIn$ = this.userQuery.isLoggedIn$.subscribe(userData => {
      this.isLoggedIn = userData && userData.id != 999 ? true : false;

      if (!this.isLoggedIn && !this.isMobile) {
        this.router.navigate(['/login']);
      }
    });

    this.cartSub$ = this.cartQuery.getCurrentCart().subscribe(cart => {
      this.cartModel = cart && cart.cartModel ? cart.cartModel : null;
    })

    if(this.isLoggedIn) {
      this.userQuery.selectedAddress$.subscribe(address => {
        this.latitude = address ? address.latitude : 0;
        this.longitude = address ? address.longitude : 0;
      });

      await this.channelService.getChannelDataCheck();
      this.channelData = this.channelService.getChannelData();
      this.channelId = this.channelData.channelId;
      this.currency = this.channelData.currency ? this.channelData.currency : null;

      if(this.route.snapshot.firstChild) {
        switch(this.route.snapshot.firstChild.url[0].path) {
          case "explore": {
            this.rewardSectionType = this.rewardSectionTypes.Explore;
            this.customCardType = this.cardTypes.store;
            break;
          }
          case "my-voucher": {
            this.rewardSectionType = this.rewardSectionTypes.MyVoucher;
            this.customCardType = this.cardTypes.use;
            break;
          }
          case "my-membership": {
            this.rewardSectionType = this.rewardSectionTypes.Membership;
            this.customCardType = this.cardTypes.membership;
            break;
          }
          default: {
            this.rewardSectionType = this.rewardSectionTypes.Explore;
            this.customCardType = this.cardTypes.store;
          }
        }
      }

      // Event when route changed on rewards
      this.router.events.forEach((event) => {
        if (event instanceof NavigationEnd && event.url === "/rewards") {
          let type: RewardSectionType;

          switch(this.route.snapshot.firstChild?.url[0]?.path) {
            case "explore":
              type = this.rewardSectionTypes.Explore;
              break;
            case "my-voucher":
              type = this.rewardSectionTypes.MyVoucher;
              break;
            case "my-membership":
              type = this.rewardSectionTypes.Membership;
              break;
            default:
              type = this.rewardSectionTypes.Explore;
          }

          this.onSwitchVoucherType(type);
        }
      });

      this.isOnFirstLoad = false;
      this.isOnLoad = true;
      await this.onLoad();
      await this.getCategories();
      this.isOnLoad = false;

      this.onUrlCopied = this.onUrlCopied.bind(this);

      this.detailDialog$ = this.rewardService.detailDialog.subscribe((value: any) => {
        this.onShowDetail(value.isShow, this.rewardSectionType === this.rewardSectionTypes.Explore && value.vchTypId ? value.vchTypId: 0,
          this.rewardSectionType !== this.rewardSectionTypes.Explore ? value.voucherNo : null, value.isBack, value.cardType);
      });

      this.storeDialog$ = this.rewardService.storeDialog.subscribe((value: any) => {
        this.onShowStore(value.isShow, value.storeList);
      });

      this.shareDialog$ = this.rewardService.shareDialog.subscribe((value: any) => {
        if (value) {
          this.onShowShare(value.isShow);
          let countryCode: string = this.channelData.countryCode ? this.channelData.countryCode + '/' : '';
          this.shareUrl = value.vchTypId ? environment.shareRewardDomain + countryCode + 'promotion-details-page/' + value.vchTypId : '';
        }
      });

      this.route.paramMap.subscribe(params => {
        this.isFromShare = params.get('vchTypId') ? true : false;
        if (this.isFromShare) {
          this.onShowDetail(true, +params.get('vchTypId'));
        }
      });

      this.qrDialog$ = this.rewardService.qrDialog.subscribe((value: any) => {
        this.onShowQr(value.isShow, null, value.voucherNo);
      });
    }

  }

  async getRewards(skip: number, searchText: string, attrOptionId: number) {
    this.isOnListLoad = true
    let rewards: VoucherTypeExtraResponse[] = [];

    rewards = <VoucherTypeExtraResponse[]>await this.rewardService.getNearbyVoucherType(this.latitude, this.longitude, this.channelId, skip, this.take, searchText, attrOptionId);
    rewards.forEach((reward: VoucherTypeExtraResponse) => this.rewards.push(reward));

    this.skip = skip + rewards.length;
    this.isMaxLoad = rewards && rewards.length === this.take ? false : true;
    this.isOnListLoad = false;
  }

  async getMember(searchText: string) {
    this.isOnListLoad = true;
    let memberDetail: MembershipWithPointStampResponse[] =[];
    memberDetail = <MembershipWithPointStampResponse[]>await this.membershipService.getCustomerMemberships(this.channelData?.channelTag, searchText);
    this.memberDetail = memberDetail;
    this.isOnListLoad = false;
  }

  async getMyRewards(skip: number, searchText: string, orderTypeFlag: string, vchCatgId: number) {
    this.isOnListLoad = true;
    let myRewardList: VoucherExtra[] = [];

    this.myRewards = <CustomerClaimedVoucherResponse>await this.rewardService.getCustomerClaimedVoucher(this.latitude, this.longitude, this.channelId, skip, this.take, searchText, orderTypeFlag, vchCatgId);

    myRewardList = this.myRewards.voucherList ? this.myRewards.voucherList : [];
    myRewardList.forEach((reward: VoucherExtra) => {
      this.myRewardList.push(reward);

      let index: number = this.myRewardCatgList.findIndex((voucher: any) => (reward.merchantId && voucher.merchantId === reward.merchantId) || (!reward.merchantId && voucher.vchCatgCode === reward.vchCatgCode));

      if (index < 0) {
        this.myRewardCatgList.push({
          vchCatgCode: reward.merchantId ? null : reward.vchCatgCode,
          merchantId: reward.merchantId ? reward.merchantId : null,
          merchantDesc: reward.merchantDesc ? reward.merchantDesc : reward.vchCatgCode,
          vouchers: [reward],
        });
      } else {
        this.myRewardCatgList[index].vouchers.push(reward);
      }
    });

    // Shift odaring voucher to the first
    let odaringIndex: number = this.myRewardCatgList.findIndex((voucher: any) => voucher.vchCatgCode && voucher.vchCatgCode === this.catgCodes.ODARING);

    if (odaringIndex > 0) {
      let spliceData: any = this.myRewardCatgList.splice(odaringIndex, 1);
      this.myRewardCatgList = spliceData.concat(this.myRewardCatgList);
    }

    this.skip = skip + myRewardList.length;
    this.isMaxLoad = myRewardList && myRewardList.length === this.take ? false : true;

    this.isOnListLoad = false;
  }

  async getCategories() {
    if (this.rewardSectionType === this.rewardSectionTypes.Explore) {
      this.categories = [];

      let rewardTypeCategories: CatgAttrOptionResponse[] = <CatgAttrOptionResponse[]>await this.rewardService.getVoucherTypeCategories();
      rewardTypeCategories = _.cloneDeep(rewardTypeCategories);

      rewardTypeCategories.map((category: CatgAttrOptionResponse, index: number) => {
        this.categories[index] = {
          id: category.attrOptionId,
          desc: category.attrOptionDesc,
        }
      });
    } else if(this.rewardSectionType === this.rewardSectionTypes.MyVoucher) {
      this.categories = [];

      let myRewardTypeCategories: VoucherCatgResponse[] = _.cloneDeep(this.myRewards.voucherCatgList);

      myRewardTypeCategories.map((category: VoucherCatgResponse, index: number) => {
        this.categories[index] = {
          id: category.vchCatgId,
          desc: category.vchCatgDesc,
        }
      });
    }

    if(this.rewardSectionType === this.rewardSectionTypes.Explore || this.rewardSectionType === this.rewardSectionTypes.MyVoucher) {
      this.cd.detectChanges();
      this.showCategoryMenuIcons();
    }
  }

  async onLoad() {
    switch(this.rewardSectionType) {
      case this.rewardSectionTypes.Explore:
        await this.getRewards(this.skip, this.searchText, this.categoryId);
        break;
      case this.rewardSectionTypes.MyVoucher:
        await this.getMyRewards(this.skip, this.searchText, this.orderTypeFlag, this.categoryId);
        break;
      case this.rewardSectionTypes.Membership:
        await this.getMember(this.searchText)
        break;
      default:
        break;
    }
  }

  async onSwitchVoucherType(rewardSectionType: RewardSectionType, allowSameType?: boolean) {
    let type: string = "";

    // Change route
    switch(rewardSectionType) {
      case this.rewardSectionTypes.Explore: {
        type = 'explore';
        this.location.replaceState('/reward/' + type);
        this.customCardType = this.cardTypes.store;
        break;
      }
      case this.rewardSectionTypes.MyVoucher: {
        type = 'my-voucher';
        this.location.replaceState('/reward/' + type);
        this.customCardType = this.cardTypes.use;
        break;
      }
      case this.rewardSectionTypes.Membership: {
        type = 'my-membership';
        this.location.replaceState('/reward/' + type);
        this.customCardType = this.cardTypes.membership;
        break;
      }
      default: {
        type = 'explore';
        this.location.replaceState('/reward/' + type);
        this.customCardType = this.cardTypes.store;
        break;
      }
    }

    if (this.rewardSectionType !== rewardSectionType || allowSameType) {
      this.rewardSectionType = rewardSectionType;

      // Reset Data
      this.skip = 0;
      this.isMaxLoad = false;
      this.categories = [];
      this.categoryId = 0;
      this.searchText = '';
      this.searchedText = '';
      this.searchFg.get('searchText').reset();
      this.orderTypeFlag = null;
      this.rewards = [];
      this.myRewardCatgList = [];
      this.myRewardList = [];
      this.memberDetail = [];

      this.isOnLoad = true;
      await this.onLoad();
      await this.getCategories();
      this.isOnLoad = false;

      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      this.currStickyStore = 0;
      this.onSwipe(this.categoryId);
    }
  }

  async onMenuChange(categoryId: number) {
    this.categoryId = categoryId;
    this.onSwipe(this.categoryId);
    this.searchText = this.searchedText = this.searchFgroup.value.searchText ? this.searchFgroup.value.searchText : "";

    // Reset Data
    this.skip = 0;
    this.isMaxLoad = false;
    this.searchedText = '';
    this.orderTypeFlag = null;
    this.rewards = [];
    this.myRewardCatgList = [];
    this.myRewardList = [];
    this.memberDetail = [];

    this.isOnLoad = true;
    await this.onLoad();
    this.isOnLoad = false;

    this.showMobileMenu = false;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.currStickyStore = 0;
  }

  async onSearch(clear?: boolean) {
    this.searchText = this.searchedText = clear ? "" : this.searchFgroup.value.searchText;

    if (clear) {
      this.searchFg.get('searchText').reset();
    }

    this.searchBarEl.nativeElement.focus();

    // Reset Data
    this.skip = 0;
    this.isMaxLoad = false;
    this.orderTypeFlag = null;
    this.rewards = [];
    this.myRewardCatgList = [];
    this.myRewardList = [];
    this.memberDetail = [];

    this.isOnLoad = true;
    await this.onLoad();
    this.isOnLoad = false;

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.currStickyStore = 0;
  }

  onShowMenu(state: boolean) {
    if (this.menuIconEl && state) {
      this.menuIconEl.nativeElement.classList.add('rotate-up');
      this.menuIconEl.nativeElement.classList.remove('rotate-down');
    }

    if (this.menuIconEl && !state) {
      this.menuIconEl.nativeElement.classList.add('rotate-down');
      this.menuIconEl.nativeElement.classList.remove('rotate-up');
    }
  }

  onShowMobileMenu() {
    this.showMobileMenu = true;
  }

  onShowDetail(isShow: boolean = false, vchTypId?: number, voucherNo? : string, isBack?: boolean, cardType?: VoucherCardType) {
    this.showStore = false;
    this.customCardTypeDetail = cardType;
    this.exploreRewardDetail = null;
    this.myRewardDetail = null;

    if (this.showQr) {
      this.onShowQr(false);
      this.isBackQRDialog = true;
    }

    if (isBack && this.isFromShare) {
      this.router.navigate(["/home"]);
    }

    if (isBack && this.isBackQRDialog) {
      this.onShowQr(true, vchTypId);
      this.isBackQRDialog = false;
    }

    this.showDetail = isShow;

    if (isShow && this.rewardSectionType === this.rewardSectionTypes.Explore) {
      this.exploreRewardDetail = this.rewards && vchTypId ? this.rewards.filter(reward => reward.vchTypId === vchTypId)[0] : null;
    }

    if (isShow && this.rewardSectionType === this.rewardSectionTypes.MyVoucher) {
      if(voucherNo){
        this.myRewardDetail = this.myRewardList && voucherNo ? this.myRewardList.filter(reward => reward.voucherNo === voucherNo)[0] : null;
      }
      else{
        this.myRewardDetail = this.myRewardList && vchTypId ? this.myRewardList.filter(reward => reward.voucherId === vchTypId)[0] : null;
      }
    }
  }

  onShowStore(isShow: boolean, storeList: StoreResponse[]) {
    this.showDetail = false;
    this.showQr = false;

    if(storeList && storeList.length === 1) {
      let globalOrderType = this.staticQrService.getChannelOrderType();
      let storeCart : OrderH = null;
      let orderType : string;

      if((!globalOrderType || globalOrderType == OrderTypeFlag.All) && this.cartModel && this.cartModel?.orderHs && this.cartModel.orderHs?.length > 0){
        storeCart = this.cartModel.orderHs.find(orderH => orderH.storeId == storeList[0].storeId);
      }

      if(storeCart){
        orderType = this.storeService.getOrderType(storeCart.orderData.sourceFlag);
      }
      else{
        orderType = storeList[0].currentOrderType;
      }
      this.storeService.preStoreNavigation(orderType, storeList[0], this.rewardSectionType === this.rewardSectionTypes.Explore);
    } else if(storeList) {
      this.showStore = isShow;
      this.storeList = storeList;
    }
  }

  onShowQr(isShow: boolean = false, voucherId?: number, voucherNo? : string) {
    if (isShow) {
      if(voucherNo){
        this.myRewardQr = this.myRewardList && voucherNo ? this.myRewardList.filter(reward => reward.voucherNo === voucherNo)[0] : null;
      }
      else{
        this.myRewardQr = this.myRewardList && voucherId ? this.myRewardList.filter(reward => reward.voucherId === voucherId)[0] : null;
      }

      if (this.myRewardQr) {
        this.showDetail = false;
        this.showQrZoom = false;
        this.showQr = isShow;
      }
    } else {
      this.showQr = isShow;
    }
  }

  onShowZoomQr() {
    this.showQr = false;
    this.showQrZoom = true;
  }

  onBackZoomQr() {
    this.onShowQr(true, null, this.myRewardQr.voucherNo);
  }

  onShowShare(isShow: any) {
    this.showShare = isShow;

    if (isShow) {
      this.showDetail = false;
      this.shareStoreName = this.rewardSectionType === this.rewardSectionTypes.Explore ? this.exploreRewardDetail?.merchantDesc : this.myRewardDetail?.merchantDesc;
    }
  }

  onSwipe(id: number) {
    // Menu swiper scroll
    let menuSwiper = document.getElementById('menu-' + id);

    if (menuSwiper && this.swiper) {
      let menuSwiperIndex = +menuSwiper.parentElement.getAttribute("data-swiper-slide-index");
      this.swiper.swiperRef.slideTo(menuSwiperIndex, 700);
    }

    if (!menuSwiper && this.swiper) {
      this.swiper.swiperRef.slideTo(0, 700);
    }
  }

  onUrlCopied(e) {
    this.isCopied = true;

    setTimeout(() => {
      this.isCopied = false;
    }, 1500);
  }

  onSlidePrev() {
    this.swiper.swiperRef.slidePrev(100);
    if (this.swiper.swiperRef.isBeginning) {
      this.renderer.setStyle(this.menuSlideLeftIconEl.nativeElement, 'display', 'none');
    } else {
      this.renderer.setStyle(this.menuSlideLeftIconEl.nativeElement, 'display', 'block');
    }
  }

  onSlideChange() {
    if (this.swiper.swiperRef.isBeginning) {
      this.renderer.setStyle(this.menuSlideLeftIconEl.nativeElement, 'display', 'none');
    } else {
      this.renderer.setStyle(this.menuSlideLeftIconEl.nativeElement, 'display', 'block');
    }
  }

  blockScroll(isShow: boolean) {
    if (isShow) {
      document.body.classList.add('p-overflow-hidden');
    } else {
      document.body.classList.remove('p-overflow-hidden');
    }
  }


  showCategoryMenuIcons() {
    if (this.swiper) {
      let width: number = this.swiper.swiperRef.width ? this.swiper.swiperRef.width : 0;
      let overflownWidth = this.swiper.swiperRef.wrapperEl.scrollWidth ? this.swiper.swiperRef.wrapperEl.scrollWidth : 0;

      if (width && overflownWidth && overflownWidth <= width) {
        this.renderer.setStyle(this.menuIconEl.nativeElement, 'display', 'none');
      } else {
        this.renderer.setStyle(this.menuIconEl.nativeElement, 'display', 'block');
      }

      if (width && overflownWidth && overflownWidth > width && !this.swiper.swiperRef.isBeginning) {
        this.renderer.setStyle(this.menuSlideLeftIconEl.nativeElement, 'display', 'block');
      } else {
        this.renderer.setStyle(this.menuSlideLeftIconEl.nativeElement, 'display', 'none');
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true : false;
    this.showCategoryMenuIcons();
  }

  @HostListener('window:scroll', ['$event'])
  async onWindowScroll() {
    // Header sticky
    if (this.headerEl) {
      let headerPosition = this.headerEl.nativeElement.getBoundingClientRect().top;
      this.isHeaderSticky = headerPosition < 0 ? true : false;
    }

    // Pagination
    const reduce = this.isMobile ? 0 : 280;
    if (!this.isOnLoad && !this.isOnListLoad && !this.isMaxLoad && this.rewardSectionType !== this.rewardSectionTypes.Membership) {
      if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - reduce)) {
        this.isOnListLoad = true;
        await this.onLoad();
        this.isOnListLoad = false;
      }
    }

    // Storename sticky
    let st = window.pageYOffset;
    let headerHeight = this.headerEl.nativeElement.offsetHeight;
    let currStoreEl = document.getElementById("storeName-" + this.currStickyStore);

    if (st > this.lastScrollTop) {
      // Downscroll
      let nextStoreEl = document.getElementById("storeName-" + (this.currStickyStore + 1));

      if (nextStoreEl && (window.pageYOffset + headerHeight) >= nextStoreEl.offsetTop - this.storeHeaderEl.nativeElement.offsetHeight) {
        let titleCasePipe = new TitleCasePipe();
        this.storeHeaderEl.nativeElement.children[0].innerText = titleCasePipe.transform(nextStoreEl.innerText);
        this.currStickyStore += 1;
      }
    } else {
      // Upscroll
      let prevStoreEl = document.getElementById("storeName-" + (this.currStickyStore - 1));

      if (prevStoreEl && (window.pageYOffset + headerHeight) <= (currStoreEl.offsetTop - this.storeHeaderEl.nativeElement.offsetHeight - 22)) {
        this.storeHeaderEl.nativeElement.children[0].innerText = prevStoreEl.innerText;
        this.currStickyStore -= 1;
      }
    }

    this.lastScrollTop = st <= 0 ? 0 : st;
  }

  ngOnDestroy() {
    this.userLoggedIn$?.unsubscribe();
    this.detailDialog$?.unsubscribe();
    this.storeDialog$?.unsubscribe();
    this.shareDialog$?.unsubscribe();
    this.qrDialog$?.unsubscribe();
    this.cartSub$?.unsubscribe();
  }
}
