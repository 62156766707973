<div dropdown class="sort-dropdown-container p-ml-auto hidden-sm"
  [ngClass]="{'sort-dropdown-container-right': dropdownRight}">
  <button class="sort-dropdown heading-11" id="button-autoclose1" dropdownToggle type="button"
    aria-controls="dropdown-autoclose1">
    <i class="oda-sort"></i>
    {{currentFilter | translate}}
    <i class="oda-chevron-down"></i>
  </button>

  <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
    <li role="menuitem" *ngFor="let item of sortList; let i = index;">
      <a class="dropdown-item" title="{{item.label | translate}}" [ngClass]="{'active': item.active}" (click)="onSorting(item, i)">
        {{item.label | translate}}
      </a>
    </li>
  </ul>
</div>

<div class="mobile-sort-button hidden-lg" (click)="onClickMobileSortButton()">
  <i class="oda-sort"></i>
  {{currentFilter | translate}}
  <i class="oda-chevron-down"></i>
</div>

<div class="mobile-sorting-dialog hidden-lg">
  <p-dialog [(visible)]="openSortDialog" position="bottom" [dismissableMask]="true" [baseZIndex]="10000" [modal]="true"
    [closable]='true' *ngIf="openSortDialog">
    <div class="sort-title">
      <i class="oda-sort p-mr-2"></i>
      {{"merchant.information.reviews.filter.title" | translate}}
    </div>

    <div style="margin-top: 20px;">
      <ng-template ngFor let-item [ngForOf]="sortList" let-i="index">
        <button class="sort-button" [ngClass]="{'active': item.active}" (click)="onSorting(item, i)">{{item.label |
          translate}}</button>
        <hr />
      </ng-template>
    </div>

  </p-dialog>
</div>
