<div class="manageAdress-container">
  <div class="alert-msg" *ngIf="showSuccessUpdate">
    <i class="color oda-check-alt"></i> {{'alert.manageAddress.des.11' | translate }}
  </div>

  <!--mobile header-->
  <div class="manageAddress-header hidden-lg">
    <i class="oda-chevron-left" [routerLink]="['/profiles']"></i>
    <h1>{{ 'navigation.Menu.des.2' | translate }}</h1>
  </div>

 <div class="content">
   <!--subtitle and add new button-->
   <div class="add-new-button">
    <h1>{{'manageAdress.header.1' | translate}}</h1>
    <div class="add-btn" [routerLink]="['/account/address/new-address']">
      <i class="oda-plus"></i>
      <h2>{{ 'button.add' | translate }}</h2>
    </div>
  </div>
  <!--saved address-->
  <div class="saveList-content">
    <app-address-list *ngIf="savedAddress" [savedAddress]="savedAddress" [isHideHeader]="true"
                      (editSelectedAddress)="editSelectedAddress($event)" (deleteSelectedAddress)="deleteSelectedAddress($event)">
    </app-address-list>
  </div>
 </div>
</div>
