import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isShowMascot'
})
export class IsShowMascotPipe implements PipeTransform {

  transform(channel: any): boolean {
    let nonMascotId : any = [
      57, // oriental dev
      //live
      36, 'orientalcoffee',
      41,'lotuscar',
    ]

    let isNonMascot : boolean = false;

    isNonMascot = nonMascotId.some(val => val == channel);

    if(isNonMascot) {
      return false;
    }
    else {
      return true;
    }
  }

}
