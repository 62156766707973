import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 , PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  defaultTheme = ['default-theme'];
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: any,
    public rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: object,
    private platformLocation: PlatformLocation,
  ) { }

  setTheme(theme: any): void {
    if (isPlatformBrowser(this.platformId)){
      const themes = theme ? theme : null;
      this.renderer = this.rendererFactory.createRenderer(null, null);
      themes.forEach(t => this.renderer.removeClass(document.body, t));
      this.renderer.addClass(document.body, theme);
    }
  }

  checkHrefToGetChannelTag(){
    if (isPlatformBrowser(this.platformId)){
      let href = this.platformLocation.href;
        let convertHref = href.toLowerCase();
        if (convertHref.includes('orientalcoffee')){
          this.setTheme(['oriental-theme']);
        }else if (convertHref.includes('lotuscar')) {
          this.setTheme(['lotuscar-theme']);
        }else {
          this.setTheme(this.defaultTheme);
        }
    }
  }

  hidePromotion(channelTag: any) {
    let isHide : boolean = false;
    let tag = channelTag.toLowerCase();
    if(tag == 'lotuscar') {
     isHide = true;
    }
    return isHide;
  }

  loadTheme(channalTag: string): void {
    let tag = channalTag ? channalTag.toLowerCase() : '';

    switch (tag) {
      case 'orientalcoffee': {
        this.setTheme(['oriental-theme']);
        break;
      }

      case 'lotuscar': {
        this.setTheme(['lotuscar-theme']);
        break;
      }

      default: {
        this.setTheme(this.defaultTheme);
        break;
      }
    }
  }
}
