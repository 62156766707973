<div class="reset-password-container">
  <h1 class="heading-5 hidden-sm">{{ 'change.password.header' | translate }}</h1>

  <div class="mobile-header hidden-lg">
    <app-back-button [color]="'var(--backbutton)'"></app-back-button>
    <h1 class="heading-14">{{ 'change.password.header' | translate}}</h1>
  </div>

  <!--form-->
  <div class="content">
    <app-reset-pass-form [pageName]="pageName" [errorCode]="errorCode" (onSubmitted)="onFormSubmit($event)"></app-reset-pass-form>
  </div>
</div>
