import { TimeInterval } from 'src/app/core/models/TimeInterval';
import { StaticQrState } from './../../../core/static-qr/static-qr.model';
import { ChannelPlatformSetResponse } from 'src/app/core/models/ChannelPlatformSetResponse';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { QrCartService } from 'src/app/core/qr-cart/qr-cart.service';
import { Subscription } from 'rxjs';
import { DetailActivity } from './../../../home/order/order.model';
import { QrCartStore } from './../../../core/qr-cart/qr-cart.store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit, ViewChild } from '@angular/core';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import * as _ from 'lodash';
import { OrderH } from 'src/app/core/models/OrderH';
import { OrderSourceFlag } from 'src/app/core/enums/OrderSourceFlag';
import { StoreAttrResponse } from 'src/app/core/models/StoreAttrResponse';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/home/order/order.service';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ChangeData } from 'ngx-intl-tel-input';
import { UserService } from 'src/app/core/user/user.service';
import { StoreMode } from 'src/app/core/enums';
import { HttpErrorResponse } from '@angular/common/http';
import { PreviousRoute } from 'src/app/core/models/PreviousRoute';
import { SetCode } from 'src/app/core/enums/SetCode';
import { StoreService } from 'src/app/store/store/store.service';
import { StorageService } from '../../services/storage.service';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { AvailableTime } from 'src/app/core/models/AvailableTime';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';
import { SessionStorageService } from '../../storage/session-storage.service';

@Component({
  selector: 'app-qr-dinein-selection',
  templateUrl: './qr-dinein-selection.component.html',
  styleUrls: ['./qr-dinein-selection.component.scss']
})
export class QrDineinSelectionComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() storeResponse : StoreResponse;
  @Input() qrTokenResponse : WebLinkTokenResponse;
  @Input() orderH : OrderH;
  @Input() dismissable : boolean = false;
  @Input() dialogPosition : string = "center";
  @Input() detailActivities : DetailActivity[];
  @Input() isStorePage : boolean;
  @Input() staticQrData : StaticQrState;
  @Input() channelId : number;

  @Output() closeDialog = new EventEmitter();
  @Output() continueDineInSelection = new EventEmitter();
  @Output() staticContinue = new EventEmitter();

  @ViewChild('target') targetElement: any;

  currentQrOrderType : string = OrderTypeFlag.DineIn;
  orderType = OrderTypeFlag;
  qrTokenResponseClone : WebLinkTokenResponse;

  bannerImg : StoreAttrResponse[] = [];
  telInpSetting = DefaultSettingService.telInpSetting;

  fgroup: UntypedFormGroup = this.fb.group({
    phoneNo: ['', Validators.compose([])]
  });
  phoneNoVal : string = '';
  mobileNo : string = '';
  isLoggedIn : boolean;
  userMobileNo : string;
  statePhoneNo : ChangeData;

  userSub : Subscription;
  formSubscription : Subscription;
  setCode = SetCode

  refNoInput : string = '';
  refCompulsory : boolean = false;
  showRef : ChannelPlatformSetResponse = null;
  requiredRefNo : boolean = false;
  isFocus : boolean = false;
  countryCode : string = "MY";

  tableNo : string;
  guestCount : number;
  staticQrDataClone : StaticQrState;
  tableLabel : string;

  mobileNumberMandatory : boolean = false;
  mobileNumberNeeded : boolean = false;
  isInvalidMobileNumber : boolean = false;

  seeMore: boolean = false;
  closeExpandSub$: Subscription;
  isRemarkExpand: boolean = false;
  isLogin : boolean = false;
  dineLabel: any;
  pickupLabel: any;
  takeawayLabel: any;

  constructor(
    private qrStore : QrCartStore,
    private router : Router,
    private orderService : OrderService,
    private fb: UntypedFormBuilder,
    private userService : UserService,
    private qrCartService : QrCartService,
    private previousRouteService : PreviousRouteService,
    private storeService : StoreService,
    private storageService : StorageService,
    private utilsService : UtilsService,
    private staticQrService : StaticQrService,
    private authService : AuthService,
    private sessionStorageService: SessionStorageService
  ) {}

  async ngOnInit() {
    this.userSub = this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      if (userData) {
        this.isLoggedIn = true;
        this.userMobileNo = userData.mobileNo;
      } else {
        this.isLoggedIn = false;
        this.userMobileNo = '';
      }
    });

    // if is dynamic qr dine in then run initial dynamic qr dine in logic
    // else run static qr dine in initial logic
    if(this.qrTokenResponse){
      await this.dynamicQrInit();
    }
    else if((this.staticQrData && this.staticQrData?.tableNo && this.isStorePage) || (!this.isStorePage && this.orderH && this.orderH?.orderData?.tableNo)){
      await this.staticQrInit();
    }

    // if storeResponse current order type = dineIn OR
    // if cart existed and sourceflag = QRdineIn/DineIn then init with DineIn order type
    if(this.storeResponse?.currentOrderType == OrderTypeFlag.DineIn || (this.orderH && (this.orderH?.orderData.sourceFlag == OrderSourceFlag.WebQrDineIn
      || this.orderH?.orderData.sourceFlag == OrderSourceFlag.AppQrDineIn || this.orderH?.orderData.sourceFlag == OrderSourceFlag.WebDineIn
      || this.orderH?.orderData.sourceFlag == OrderSourceFlag.AppDineIn))){
      this.currentQrOrderType = OrderTypeFlag.DineIn;
    }
    else{
      this.currentQrOrderType = OrderTypeFlag.Pickup;
    }

    if(this.storeResponse){
      this.bannerImg = this.storeResponse.storeAttrs.filter(val => val.attrCode.includes("QRBANNERIMG"));
    }

    let tableLabelSetting : any;
    if(!this.isStorePage && this.orderH){
      tableLabelSetting = this.orderH.platformSets.find(setting => setting.setCode == SetCode.DITBLNOLBL);
    }
    else if(this.isStorePage){
      tableLabelSetting = this.storeResponse.platformSets.find(setting => setting.setCode == SetCode.DITBLNOLBL);
    }
    else{
      tableLabelSetting = null;
    }

    this.tableLabel = tableLabelSetting ? tableLabelSetting.setValue : null;

    //order type label settting
    let dineLabelSetting = this.storeResponse.platformSets.find(setting => setting.setCode == this.setCode.OT_DINE_LBL);
    let pickUpLabelSetting = this.storeResponse.platformSets.find(setting => setting.setCode == this.setCode.OT_PICKUP_LBL);
    let takeAwayLabelSetting = this.storeResponse.platformSets.find(setting => setting.setCode == this.setCode.OT_TakeAway_LBL);
    this.dineLabel = dineLabelSetting?.setValue ? dineLabelSetting?.setValue : 'order.type.select.dine.in';
    this.pickupLabel = pickUpLabelSetting?.setValue ? pickUpLabelSetting?.setValue : "order.type.select.pick.up";
    this.takeawayLabel = takeAwayLabelSetting?.setValue ? takeAwayLabelSetting?.setValue : 'qr.merchant.order.type.1';

    // get value when input on change
    this.formSubscription = this.fgroup.get('phoneNo').valueChanges.subscribe(val => {
      this.mobileNumberNeeded = false;
      this.isInvalidMobileNumber = false;
      if(val){
        this.phoneNoVal = val.number ? val.number : '';
      }
      else{
        this.phoneNoVal = '';
      }
    });

    let isMobileView = window.innerWidth <= 991 ? true: false;
    if ((isMobileView && this.targetElement?.nativeElement.offsetHeight > 123) || (!isMobileView && this.targetElement?.nativeElement.offsetHeight >= 139)) {
      this.seeMore = true
    }

    this.closeExpandSub$ = this.orderService.closeExpandedItem$.subscribe(isCloseExpanded => {
      if (isCloseExpanded) {
        this.isRemarkExpand = false;
      }
    });

    this.getFormControl('phoneNo').valueChanges.subscribe((dt) => {
      this.isInvalidMobileNumber = false;
    });

    if(this.mobileNumberMandatory && this.isLoggedIn){
      this.fg.controls['phoneNo'].disable();
    }
  }

  async dynamicQrInit(){
    this.qrTokenResponseClone = _.cloneDeep(this.qrTokenResponse);
    this.tableNo = this.qrTokenResponse.tableNo;
    this.guestCount = this.qrTokenResponse.guestCount == 0 ? 1 : this.qrTokenResponse.guestCount;

    let refValueStored : string;
    if(this.orderH){
      refValueStored = this.orderH.orderData.orderC.refNo ? this.orderH.orderData.orderC.refNo : '';
    }
    else{
      refValueStored = this.qrCartService.getRefValue();
    }

    this.refNoInput = refValueStored ? refValueStored : '';
    if(this.storeResponse){
      this.showRef = await this.storeService.getPlatformSetting(this.storeResponse.platformSets, this.setCode.REFNOLABEL, this.storeResponse.storeId);

      if(this.showRef){
        let isCompulsory = await this.storeService.getPlatformSetting(this.storeResponse.platformSets, this.setCode.ASKREFNO, this.storeResponse.storeId);
        if(isCompulsory){
          this.refCompulsory = isCompulsory.setValue == "1" ? true : false;
        }
      }

      let mobileCompulsorySetting = this.storeResponse.platformSets.find(settings => settings.setCode == this.setCode.DYNAREQTEL);
      this.mobileNumberMandatory = mobileCompulsorySetting && mobileCompulsorySetting?.setValue == "1" ? true : false;
    }

    if(this.mobileNumberMandatory){
      this.checkMobileNo();
    }
  }

  async staticQrInit(){
    this.staticQrDataClone = _.cloneDeep(this.staticQrData);

    if (this.storeResponse) {
      let mobileCompulsorySetting = this.storeResponse.platformSets.find(settings => settings.setCode == this.setCode.STATICREQTEL);
      this.mobileNumberMandatory = mobileCompulsorySetting && mobileCompulsorySetting?.setValue == "1" ? true : false;
    }

    if(this.mobileNumberMandatory){
      this.checkMobileNo();
    }

    if(this.isStorePage){
      this.tableNo = this.staticQrDataClone && this.staticQrDataClone?.tableNo ? this.staticQrDataClone.tableNo : null;
      this.guestCount = this.staticQrDataClone && this.staticQrDataClone?.guestCount ? this.staticQrDataClone?.guestCount : 1;
    }
    else{
      this.tableNo = this.orderH.orderData.tableNo;
      this.guestCount = this.orderH.orderData.guestCount;
    }

    this.detailActivities = this.detailActivities? this.detailActivities: [];

    this.showRef = null;
    this.refCompulsory = false;
  }

  async checkMobileNo(){
    let phoneInfo = this.storageService.getCachedNumber();
    if(this.isLoggedIn){
      let parsedPhoneData = await this.utilsService.parseGivenPhoneNumber("+" + this.userMobileNo);

      this.countryCode = parsedPhoneData.country;
      this.getFormControl('phoneNo').setValue(parsedPhoneData.nationalNumber);
      this.getFormControl('phoneNo').disable();
    }
    else if(phoneInfo && !this.isLoggedIn){
      this.statePhoneNo = phoneInfo;
      // get mobile number
      this.mobileNo = this.formatPhoneNo(phoneInfo);
      // get dial code e.g 60
      let dialCode = phoneInfo.dialCode.replace('+', '');
      // throw in mobile number with '+' to a library to know its origin
      let parsedPhoneData = await this.utilsService.parseGivenPhoneNumber("+" + dialCode + this.mobileNo);

      this.countryCode = parsedPhoneData.country;
      this.getFormControl('phoneNo').setValue(parsedPhoneData.nationalNumber);
      this.getFormControl('phoneNo').enable();
    }
    else{
      this.statePhoneNo = null;
      this.mobileNo = '';
      this.countryCode = localStorage.getItem('countryCode') ? localStorage.getItem('countryCode') : 'MY';
      this.getFormControl('phoneNo').setValue(this.mobileNo);
      this.getFormControl('phoneNo').enable();
    }
  }

  ngAfterViewInit(): void {
    let telInput = document.getElementById("phoneNo");

    if(telInput){
      telInput.addEventListener('focus', () => { this.onTelInputFocus() });
      telInput.addEventListener('blur', () => { this.onTelInputBlur() });
    }
  }

  ngOnDestroy(){
    this.formSubscription?.unsubscribe();
    this.userSub?.unsubscribe();

    if(!this.isLogin){
      this.previousRouteService.removePreviousRoute();
    }
  }

  changeQrOrderType(orderType : string){
    if((this.qrTokenResponse && orderType == OrderTypeFlag.Pickup && !this.storeResponse.qrTakeawayFlag) ||
      (!this.qrTokenResponse && orderType == OrderTypeFlag.Pickup && !this.storeResponse.pickupFlag)){
      return;
    }
    else if((this.qrTokenResponse && orderType == OrderTypeFlag.DineIn && !this.storeResponse.qrDineFlag) ||
      (!this.qrTokenResponse && orderType == OrderTypeFlag.DineIn && !this.storeResponse.dineFlag)){
      return;
    }

    this.currentQrOrderType = orderType;
  }

  plusPax(){
    this.guestCount += 1;
  }

  minusPax(){
    if(this.guestCount == 1){
      return;
    }

    this.guestCount -= 1;
  }

  closeDineInPopup(){
    this.closeDialog.emit();
  }

  async onClickContinue(){
    // check if reference number is compulsory and if its value exists
    if(this.showRef && this.refCompulsory && !this.refNoInput){
      this.requiredRefNo = true;
      return;
    }

    if (this.mobileNumberMandatory) {
      // check if the phoneNo input is valid or not, if it is logged in user and if it is dynamic QR dine in
      let phoneNo = this.getFormControl('phoneNo').value;
      if(phoneNo){
        const mobile = phoneNo && phoneNo.e164Number;
        let invalidPhoneNo: boolean = await this.utilsService.phoneNoValidation(mobile);
        if(invalidPhoneNo && !this.isLoggedIn){
          this.isInvalidMobileNumber = true;
          this.focusOnMobileInputBox();
          this.scrollToInputBox();
          return;
        }
      }else {
        this.mobileNumberNeeded = true;
        this.focusOnMobileInputBox();
        this.scrollToInputBox();
        return;
      }
    }

    // reference number input box have value then we save it in session storage
    if(this.refNoInput){
      this.sessionStorageService.setItem("refValue", this.refNoInput);
    }
    else{
      this.sessionStorageService.removeItem("refValue");
    }

    if(this.qrTokenResponse){
      await this.dynamicQrContinue();
    }
    else{
      await this.staticQrContinue();
    }
  }

  onClickViewOrder(){
    // check if dynamic or static qr
    if(this.qrTokenResponse) {
      this.orderService.qrOrderSummaryPreNavigation(this.detailActivities);
    } else {
      this.sessionStorageService.removeItem("orderPayAtCounter");
      this.sessionStorageService.removeItem("orderSummary");
    }
    this.orderService.setIsNavigated(true);
    this.router.navigate(['order-summary']);
    this.closeDialog.emit();
  }

  async staticQrContinue(){
    this.staticQrDataClone.guestCount = this.guestCount ?? 1;
    this.staticQrDataClone.orderType = this.currentQrOrderType;
    this.staticQrService.update(this.staticQrDataClone);

    let timeObject = {} as AvailableTime;
    timeObject.chosenTime = {} as TimeInterval;

    let newDate = new Date();
    timeObject.date = moment(newDate).format("YYYY-MM-DD");
    timeObject.isAsap = true;
    timeObject.isToday = true;
    timeObject.chosenTime.label = "ASAP";
    timeObject.chosenTime.value = null;

    if(this.currentQrOrderType == OrderTypeFlag.Pickup){
      timeObject.orderType = OrderTypeFlag.Pickup;
    }
    else{
      timeObject.orderType = OrderTypeFlag.DineIn;
    }

    if(this.isStorePage){
      this.storeService.timeChangeChecking$.next(timeObject);
      if(this.mobileNumberMandatory){
        let phoneData = this.getFormControl('phoneNo').value;
        this.storageService.saveSubmittedNumber(phoneData);
      }
    }
    else{
      this.staticContinue.emit({chosenSchedule: timeObject, toChangeValue: true});
    }
  }

  async dynamicQrContinue(){
    // set global guest count back to qr token response's guest count
    this.qrTokenResponseClone.guestCount = this.guestCount;
    // update it back to state
    this.qrStore.update({qrTokenResponse: this.qrTokenResponseClone});

    // if not store page and not logged in will verify phone number entered
    // else continued with output event
    if(!this.isStorePage){
      if(!this.isLoggedIn && this.mobileNumberMandatory){
        let phoneData = this.getFormControl('phoneNo').value;
        if(phoneData){
          await this.verifyPhoneNo(phoneData, true, false);
        }
      }
      this.continueDineInSelection.emit(this.currentQrOrderType);
    }
    else{
      // if is store page then verify phone number if logged in else just continue
      if(this.isLoggedIn){
        this.continueDineInSelection.emit(this.currentQrOrderType);
      }
      else{
        if (this.mobileNumberMandatory) {
          let phoneData = this.getFormControl('phoneNo').value;

          let toProceed = this.toCheckMobileNumber(phoneData);
          if(!toProceed){
            return;
          }

          if(phoneData){
            await this.verifyPhoneNo(phoneData, true);
          }
          else if(this.statePhoneNo){
            await this.verifyPhoneNo(this.statePhoneNo, false);
          }
        }else{
          this.continueDineInSelection.emit(this.currentQrOrderType);
        }
      }
    }
  }

  async verifyPhoneNo(phoneInfo : ChangeData, toSavePhoneInfo : boolean, toVerify : boolean = true){
    if(this.statePhoneNo && phoneInfo.e164Number == this.statePhoneNo.e164Number){
      this.continueDineInSelection.emit(this.currentQrOrderType);
      return;
    }

    // if save phone number flag is true then we will run save phone number logic
    // else we wont update local storage
    if(toSavePhoneInfo){
      this.storageService.saveSubmittedNumber(phoneInfo);
    }

    if(toVerify){
      let phoneNo = this.formatPhoneNo(phoneInfo); //get phone number
      let dialCode = phoneInfo.dialCode.replace('+', ''); //get dial code e.g 60

      let resp: any = await this.userService.getCustomerProfileStatus(dialCode + phoneNo, "");

      //if user exists then go to mobile detected page
      if(resp instanceof HttpErrorResponse === false){
        // save customer profile status to user service
        this.userService.setCustomerProfileStatus(resp);

        // save current qr order type to use back after login
        this.qrCartService.savedQrOrderType(this.currentQrOrderType);
        let previousRoute = {} as PreviousRoute;
        previousRoute.routeName = this.router.url;
        this.previousRouteService.savedPreviousRoute(previousRoute);

        // go to password or otp login page
        this.isLogin = true;
        await this.authService.loginDataInit(phoneInfo);
      }
      else{
        this.continueDineInSelection.emit(this.currentQrOrderType);
      }
    }
  }

  private getFormControl(formControlName: string) {
    return this.fg.controls[formControlName];
  }

  private formatPhoneNo(phoneObj: ChangeData) {
    return phoneObj.e164Number? phoneObj.e164Number.replace(phoneObj.dialCode || '', ''): '';
  }

  get fg() { return this.fgroup }

  get f() { return this.fg.controls; }

  onRefInputFocus(){
    this.requiredRefNo = false;
  }

  onTelInputFocus(){
    this.isFocus = true;
  }

  onTelInputBlur(){
    this.isFocus = false;
  }

  toCheckMobileNumber(phoneInfo : ChangeData){
    let toProceed : boolean = true;
    if(this.mobileNumberMandatory){
      if(!phoneInfo && !this.statePhoneNo){
        this.mobileNumberNeeded = true;
        this.focusOnMobileInputBox();
        this.scrollToInputBox();
        toProceed = false;
      }
    }

    return toProceed;
  }

  focusOnMobileInputBox(){
    let inputElement = document.getElementById("phoneNo");
    inputElement.focus();
  }

  scrollToInputBox(){
    let scrollContainer = document.getElementById("qr-overflow-container");
    scrollContainer.scrollTo({
      top: scrollContainer.scrollHeight,
      behavior: 'smooth'
    })
  }

  onRemarkExpand() {
    this.isRemarkExpand = this.isRemarkExpand ? false : true;
    document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
