<div class="setting-container">
    <div class="header">
        <app-back-button [color]="'var(--backbutton)'"></app-back-button>
        <h1>{{ 'navigation.Menu.des.settings' | translate }}</h1>
    </div>
    <div class="content">
        <h1 class="profile-heading-1" (click)="onNavigate('faq')">
        <i class="oda-faq"></i>
        {{'navigation.Menu.des.13' | translate}}
        </h1>
        <hr />
        <h1 class="profile-heading-1" (click)="onNavigate('privacyPolicy')">
        <i class="oda-shield-lock"></i>
        {{'navigation.Menu.des.14' | translate}}
        </h1>
        <hr />
        <h1 class="profile-heading-1" (click)="onNavigate('tnc')">
        <i class="oda-card-info"></i>
        {{'navigation.Menu.des.15' | translate}}
        </h1>
        <hr />
        <app-language></app-language>
        <hr />
    </div>
    <div class="footer">
        <button type="button" class="btn-delete-account" (click)="onClickDeleteAcc()">{{ 'button.delete.account' | translate }}</button>
    </div>

    <p-dialog [(visible)]="showDeleteAccTncDialog" class="delete-acc-tnc-dialog" [modal]=true [dismissableMask]=true [draggable]=false [showHeader]="false" *ngIf="showDeleteAccTncDialog">
        <app-delete-acc-tnc (onHide)="showDeleteAccTncDialog = false" (onContinue)="showDeleteAccDialog = true;"></app-delete-acc-tnc>
    </p-dialog>

    <p-dialog [(visible)]="showDeleteAccDialog" class="delete-acc-dialog" [modal]=true [dismissableMask]=true [draggable]=false *ngIf="showDeleteAccDialog">
        <app-delete-acc (onHide)="showDeleteAccDialog = false"></app-delete-acc>
    </p-dialog>
</div>
