<div class="payment-method-container">

  <div class="mobile-header" *ngIf="isMobileView">
    <div class="sub-header">
      <app-back-button class="back-btn" [color]="'var(--backbutton)'"></app-back-button>
      <h1>{{ 'navigation.Menu.des.3' | translate }}</h1>
    </div>
  </div>

  <h1 class="savedCard-header" *ngIf="displayCard">{{'order.payment.sub.header.6' | translate}}</h1>

  <div class="savedCard-content">
    <div class="savedCard-detail" *ngFor="let card of savedCard">
      <img class="p-mr-2" [src]="'assets/credit-card/' + card.cCardType + '.svg'" *ngIf="card.cCardType != 99" alt="payment card" loading="lazy" />
      <h1>**** **** **** {{card.cCardDesc | slice:-4 }}</h1>

      <div class="savedCard-actionBtn">
        <!--trash btn-->
        <button class="trash-btn" (click)="card.showDeleteButton = true" *ngIf="!card.showDeleteButton && !isMobileView"><i class="oda-d-trash duotone"></i></button>
        <button class="trash-btn" (click)="openDialog(card)" *ngIf="!card.showDeleteButton && isMobileView"><i class="oda-d-trash duotone"></i></button>
        <!--cancel bth-->
        <button class="cancel-btn" (click)="card.showDeleteButton = false" *ngIf="card.showDeleteButton && !isMobileView">{{'button.cancel' | translate}}</button>
        <!--delete btn-->
        <button class="delete-btn" (click)="deleteSavedCard(card)" *ngIf="card.showDeleteButton && !isMobileView">{{'button.delete' | translate}}</button>
      </div>
    </div>
  </div>

  <div class="emptySavedCard" *ngIf="!displayCard">
    <img src="assets/mascot/Mascot_payment-card-Grey@2x.webp" alt="empty save card" *ngIf="channelId | isShowMascot" />
    <span class="no-saved-card-desc">{{'profile.payment.card.nosaved.des' | translate}}</span>
  </div>
</div>

<div class="removeDialog">
  <p-dialog position="bottom" [(visible)]="displayRemoveDialog" *ngIf="displayRemoveDialog">
    <div class="remove-content">
      <div class="trash-icon">
        <i class="oda-d-trash duotone"></i>
      </div>
      <h1>{{'alert.profile.payment.methods.delete.des' | translate}}</h1>
      <button class="delete-btn" (click)="deleteSavedCard(this.selectedCard)">{{'button.payment.remove.card' | translate}}</button>
      <button class="cancel-btn" (click)="displayRemoveDialog = false">{{'button.cancel' | translate}}</button>
    </div>
</p-dialog>
</div>
