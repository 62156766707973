<div class="address-form-container">
  <div class="addFrom-header">
    <app-back-button class="hidden-lg back-btn" [color]="'var(--backbutton)'"
                     [manualBackEvent]="true" (onManualBackEvent)="backPreviousPage()"></app-back-button>
    <h1 class="addressForm-header" *ngIf="!editSelectedAddress">{{'address.form.title.add.new.address' | translate}}</h1>
    <h1 class="addressForm-header" *ngIf="editSelectedAddress">{{'address.form.title.edit.saved.address' | translate}}</h1>
  </div>

  <form focusInvalidInput [formGroup]="addressForm" (ngSubmit)="onSubmit()">
    <!-- search address dropdown -->
    <div class="content">
      <h1 class="label-heading hidden-sm" *ngIf="this.router.url !== '/home'">{{'address.form.address' | translate}}<span>*</span></h1>
      <app-address-search (outputSelectedAddress)="selectAddress($event)" [searchValue]="this.searchValue"
                        [isAddNewAddress]="isAddNewAddress" [isAddNewAddressDialog]="isAddNewAddressDialog">
      </app-address-search>
    </div>

    <!--address name-->
    <div class="content">
      <h1 class="label-heading">{{'address.form.address.name' | translate}}<span>*</span></h1>
      <input type="text" maxlength="100" type="text" pInputText formControlName="addressDesc" class="form-control address-name"
             placeholder="{{ 'address.label.name' | translate }}"
              #addressNamevalue appInputTrim/>
      <!--clear input field button-->
      <button type="reset" label="" *ngIf="addressNamevalue.value && addressNamevalue.value.length > 0">
        <i class="oda-times"></i>
      </button>
      <i class="oda-times" *ngIf="addressNamevalue.value" ></i>
    </div>

    <!--label-->
    <div class="content">
      <h1 class="label-heading" style="margin-bottom: 0px;">{{'address.form.label' | translate}}<span>*</span></h1>
      <div class="label_warpper">

        <input class="label_radio" id="label_one" name="addressIcon" type="radio" [value]="addressType.home"
               formControlName="addressIcon">
        <label class="label_option_container" id="one-label" for="label_one">
          <i class="oda-d-favchannel duotone label_icon"></i>
          <span>{{'address.label.home' | translate}}</span>
        </label>

        <input class="label_radio" id="label_two" name="addressIcon" type="radio" [value]="addressType.work"
               formControlName="addressIcon">
        <label class="label_option_container" id="two-label" for="label_two">
          <i class="oda-d-briefcase duotone label_icon"></i>
          <span>{{'address.label.work' | translate}}</span>
        </label>

        <input class="label_radio" id="label_three" name="addressIcon" type="radio" [value]="addressType.other"
               formControlName="addressIcon">
        <label class="label_option_container" id="three-label" for="label_three">
          <i class="oda-d-star duotone label_icon"></i>
          <span>{{'address.label.other' | translate}}</span>
        </label>
      </div>
    </div>

    <!--floor/unit-->
    <div class="content" *ngIf="channelData && channelData.addrSysFlag !== addrSysFlag.Predefined">
      <h1 class="label-heading">{{'address.form.floor.unit' | translate}}</h1>
      <input type="text" maxlength="50" pInputText formControlName="unitNo" class="form-control" placeholder="{{'input.placeholder.14' | translate}}" appInputTrim>
    </div>

    <!--note to driver-->
    <div class="content">
      <h1 class="label-heading">{{'address.form.note.to.driver' | translate}}</h1>
      <textarea rows="5" cols="30" maxlength="254" pInputTextarea formControlName="remarks" placeholder="{{'address.form.notetodriver.example' | translate}}" appInputTrim></textarea>
    </div>

    <!--default address-->
    <div class="check-box-content" *ngIf="channelData && channelData.addrSysFlag === addrSysFlag.Predefined">
      <label class="checkbox-container" for="defaultAddress">
        <input id="defaultAddress" type="checkbox" formControlName="defaultFlag">
        <span class="checkmark address-checkmark"></span>
        <span>{{'address.form.set.default.address' | translate}}</span>
      </label>
    </div>

    <!--update and save button-->
    <div class="saveNupdate-btn">
      <button type="submit" *ngIf="!editSelectedAddress"
              [disabled]="addressForm.valid ? false : true"
              [ngClass]="{'disabled-button': !addressForm.valid}">{{'address.form.button.save' | translate}}</button>
      <button type="submit" *ngIf="editSelectedAddress">{{'address.form.button.update' | translate}}</button>
    </div>

  </form>
</div>
