import { QueueService } from './../../queue/queue/queue.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/core/user/user.service';
import { LoginForm } from '../models';
import { RoutingService } from '../services/routing.service';
import { PageName } from 'src/app/core/enums';
import { ActivatedRoute, Router } from '@angular/router';
import { OtpService } from '../services/otp.service';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';
import { Location } from "@angular/common";
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OtpComponent implements OnInit, OnDestroy {

  isSignUpForm: boolean = false;
  showHeaderBackBtn: boolean = false;
  isPhoneNoExist: boolean = false;
  loginForm: LoginForm = null;
  mobileNoParam: string = "";
  mobileNo: string = "";
  isQueue : boolean = false;

  showContinueAsGuest : boolean = true;
  navigateToPayment: boolean = false;
  isFromVoucher : boolean = false;

  constructor(
    private userService: UserService,
    private routingService: RoutingService,
    private route: ActivatedRoute,
    private otpService: OtpService,
    private queueService : QueueService,
    private previousRouteService : PreviousRouteService,
    private router : Router,
    private authService : AuthService,
    private sessionStorageService: SessionStorageService,
    private location: Location,
    private themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.themeService.checkHrefToGetChannelTag();
    this.route.queryParams.subscribe((param) => {
      this.mobileNoParam = param['phoneNo']? param['phoneNo']: '';
    });

    this.isPhoneNoExist = history.state?.phoneNoExist? history.state.phoneNoExist: this.mobileNoParam? true: false;
    this.loginForm = history.state?.loginForm;
    this.showContinueAsGuest = this.userService.getShowContinueAsGuestFlag();

    const otpObj = this.otpService.getOtpObj();
    this.mobileNo = otpObj && otpObj.mobileNo? otpObj.mobileNo: this.mobileNoParam? this.mobileNoParam: this.mobileNo;

    this.isSignUpForm = history.state?.signupForm;

    // check if queue data exists in session storage
    let queueData = this.queueService.getQueueData();
    this.isQueue = queueData ? true : false;

    let cachePaymentFlag = JSON.parse(this.sessionStorageService.getItem("navigateToPayment"));
    this.navigateToPayment = cachePaymentFlag ? cachePaymentFlag.toPayment : false;

    let fromVoucher =JSON.parse(this.sessionStorageService.getItem("fromVoucher"));
    this.isFromVoucher = fromVoucher ? fromVoucher : false;
  }

  onShowBackBtn(showBtn?: boolean) {
    this.showHeaderBackBtn = showBtn;
  }

  changeOtpPhone() {
    this.routingService.navigate(PageName.LoginPage, { mobileNo: this.loginForm.phoneNo });
  }

  onClickContinueAsGuest(){
    if(this.isQueue){
      this.queueService.setCanQueueRegister(false);
      this.router.navigate(['queue/complete-queue-profile'], { replaceUrl: true });
    }else if(this.isFromVoucher) {
      this.onClickBack();
    }
    else if (this.navigateToPayment) {
      let routeName = this.navigateToPayment ? 'order-payment' : null;
      if (routeName) {
        this.router.navigate([routeName], { replaceUrl: true, state: { reinit: true } });
      } else {
        this.backPrevious();
      }
    }
    else {
      this.backPrevious();
    }
  }

  backPrevious(){
    // get previous route from store area and remove after that
    let previousRoute = this.previousRouteService.getPreviousRoute();
    this.sessionStorageService.removeItem('previousRoute');
    // navigate by url and replace url
    this.router.navigateByUrl(previousRoute.routeName, {replaceUrl: true});
}

  ngOnDestroy(): void {
    this.userService.remove(999);
    this.authService.removeSubmittedLoginFlag();
  }

  onClickBack(){
    let backRoute = this.previousRouteService.getPaymentBackUrl();
    if(backRoute){
      this.router.navigateByUrl(backRoute);
    }else {
      this.location.back();
    }
  }
}


