import { UpdateCustomerRequest } from './../../core/models/UpdateCustomerRequest';
import { CheckoutOrderRequest } from './../../core/models/CheckoutOrderRequest';
import { Injectable } from "@angular/core";
import { HttpErrorResponse } from '@angular/common/http';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

import { ActivitySummary, DetailActivity } from "./order.model";
import { OrderStore } from "./order.store";
import { OrderQuery } from "./order.query";
import { StoreMode } from 'src/app/core/enums';
import { HttpHeaderType } from "src/app/core/enums/HttpHeaderType";
import { CustomHeader, CustomRequest } from "src/app/core/models/CustomRequest";
import { CustomService } from "src/app/core/services/custom.service";
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import { environment } from 'src/environments/environment';
import { ActivityType } from "src/app/core/enums/ActivityType";
import { OrderD } from 'src/app/core/models/OrderD';
import { OrderPaymentSafetyCheckRequestModel } from 'src/app/core/models/OrderPaymentSafetyCheckRequestMode';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({ providedIn: 'root' })
export class OrderService {
  accessToken: string = "";
  refreshToken: string = "";
  curUser: User | null = null;
  activities: ActivitySummary[];
  page = new Subject<number>();
  orderItem = new Subject<OrderD>();
  currCode = new Subject<string>();
  isNavigated : boolean;
  tokenExpired$ = new Subject<boolean>();
  closeExpandedItem$ = new Subject<boolean>();
  isShowQR = new Subject<{isShow: boolean}>();

  constructor(private orderStore: OrderStore,
    private orderQuery: OrderQuery,
    private userService: UserService,
    private customService: CustomService,
    private sessionStorageService: SessionStorageService
    ) {

    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      if(dt){
        this.accessToken = dt && dt['accessToken']? dt['accessToken']: '';
        this.refreshToken = dt && dt['refreshToken']? dt['refreshToken']: '';
      }
      this.curUser = dt? dt : null;
    });

  }

  getOrders() {
    return this.orderQuery.selectAll();
  }

  getActiveOrder() {
    return this.orderQuery.selectActive();
  }

  getCount() {
    return this.orderQuery.selectCount();
  }

  add(orders: DetailActivity[]) {
    this.orderStore.add(orders);
  }

  update(orders: DetailActivity[]){
    this.orderStore.update(orders);
  }

  removeAll() {
    this.orderStore.remove();
  }

  setPage(id: number){
    this.orderStore.setActive(id);
  }

  setNextPage(){
    this.orderStore.setActive({ next: true });
  }

  setPrevPage(){
    this.orderStore.setActive({ prev: true });
  }

  getActiveId(){
    return this.orderQuery.getActiveId();
  }

  saveCurrentPage(currentPageInfo : string){
    this.sessionStorageService.setItem('currentPage', currentPageInfo);
  }

  getCurrentPage(){
    let currentPage = this.sessionStorageService.getItem('currentPage');
    return currentPage ? JSON.parse(currentPage) : null;
  }

  removeCurrentPageStored(){
    if(this.sessionStorageService.getItem('currentPage')){
      this.sessionStorageService.removeItem('currentPage');
    }
  }

  openSessionExpireMessage(){
    this.tokenExpired$.next(true);
  }

  closeSessionExpireMessage(){
    this.tokenExpired$.next(false);
  }

  async getActivitySummary(skip: number, take: number, isActive: boolean, isComplete?: boolean, channelTag?: string) {
    let respDt = null;
    respDt = await this.reqGetActivitySummary(this.accessToken, skip, take, isActive, isComplete, channelTag);

    return respDt;
  }

  async isPaymentSuccess(payTranId: number, channelTag?: string): Promise<DetailActivity[]> {
    let respDt = null;
    let respondData: DetailActivity[] = [];

    respDt = await this.reqGetOrderSummary(this.accessToken, payTranId, channelTag);

    if(!(respDt instanceof(HttpErrorResponse))) {
      respondData = respDt && respDt['body'] ? respDt['body'] : null;
    }

    return respondData;
  }

  async getOrderSummary(payTranId: number, addState: boolean, channelTag?: string) {
    let respDt = null;

    respDt = await this.reqGetOrderSummary(this.accessToken, payTranId, channelTag);

    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;

      respDt.forEach(function(order: DetailActivity, order_i: number){
        respDt[order_i].id = order.orderNo;
      }.bind(this));

      this.orderStore.setLoading(true);
      if(addState){
        this.removeAll();
        this.add(respDt);
        let currentPageData = this.getCurrentPage();
        if(currentPageData){
          let detailActivity = respDt[0]?.find(val => val.orderNo == currentPageData.orderNo);
          let orderIndex = respDt[0]?.indexOf(detailActivity);
          let currentSlide = orderIndex + 1;

          if(!detailActivity){
            this.removeCurrentPageStored();
          }
          else if(detailActivity && currentSlide != currentPageData.pageNo){
            let currentPageString = JSON.stringify({orderNo : detailActivity.orderNo, pageNo: currentSlide});
            this.saveCurrentPage(currentPageString);
          }

          this.orderStore.setActive(detailActivity ? detailActivity.orderNo : respDt[0]?.orderNo);
        }
        else{
          this.setPage(respDt[0]?.orderNo);
        }
      }
      this.orderStore.setLoading(false);
    }

    return respDt;
  }

  async getActivityDetailWithoutState(activityId: number){
    let respDt = null;

    respDt = await this.reqGetActivityDetail(this.accessToken, activityId);

    if(!(respDt instanceof HttpErrorResponse)){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    else{
      respDt = null;
    }

    return respDt;
  }

  async getActivityDetail(activityId: number, addState : boolean, channelTag?: string) {
    let respDt = null;
    let result = [];

    respDt = await this.reqGetActivityDetail(this.accessToken, activityId, channelTag);

    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;

      respDt.id = respDt.orderNo;

      this.orderStore.setLoading(true);
      if(addState){
        this.removeAll();
        this.add(respDt);
        this.setPage(respDt.orderNo);
      }
      this.orderStore.setLoading(false);

      result.push(respDt);

      return result;
    }

    return respDt;
  }

  async getOrderDataHistory(orderTranId: number) {
    let respDt = null;
    respDt = await this.reqGetOrderDataHistory(this.accessToken, orderTranId);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async requestCancelOrder(orderTranId: number) {
    let respDt = null;
    respDt = await this.reqCancelOrder(this.accessToken, orderTranId);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async requestCheckOut(checkoutInfo : CheckoutOrderRequest, guestAccessToken?: string){
    let respDt = null;
    let accessToken = this.accessToken ? this.accessToken : guestAccessToken;
    respDt = await this.reqCheckoutOrder(accessToken, checkoutInfo);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async acceptOrder(orderTranId: number) {
    let respDt = null;
    respDt = await this.reqAcceptOrder(this.accessToken, orderTranId);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async requestRefundOrder(formData: FormData) {
    let respDt = null;
    respDt = await this.reqRefundOrder(this.accessToken, formData);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async requestReceipt(activityId: number, email: string) {
    let respDt = null;
    respDt = await this.reqReceipt(this.accessToken, activityId, email);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async updateChatId(chatId: string, activityId?: number) {
    let respDt = null;
    respDt = await this.reqUpdateChatId(this.accessToken, chatId, activityId);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async confirmPickupOrder(orderTranId: number) {
    let respDt = null;
    respDt = await this.reqConfirmPickupOrder(this.accessToken, orderTranId);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async updateCustomer(updateCustomerRequest : UpdateCustomerRequest){
    let respDt = null;
    respDt = await this.reqUpdateCustomer(this.accessToken, updateCustomerRequest);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }

    return respDt;
  }

  async getActivityByOrders(orderTranId : number[], channelTag : string, addState : boolean = true){
    let respDt = null;
    respDt = await this.reqGetActivityByOrders(this.accessToken, channelTag, orderTranId);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;

      respDt.forEach(function(order: DetailActivity, order_i: number){
        respDt[order_i].id = order.orderNo;
      }.bind(this));

      this.orderStore.setLoading(true);
      if(addState){
        this.removeAll();
        this.add(respDt);
        let currentPageData = this.getCurrentPage();
        if(currentPageData){
          this.setPage(currentPageData.orderNo ? currentPageData.orderNo : respDt[0]?.orderNo);
        }
        else{
          this.setPage(respDt[0]?.orderNo);
        }
      }
      this.orderStore.setLoading(false);
    }

    return respDt;
  }

  async getActivityByOrderTranId(orderTranId: number[], addState : boolean, channelTag: string) {
    let respDt = null;
    respDt = await this.reqGetActivityByOrders(this.accessToken, channelTag, orderTranId);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;

      respDt.id = respDt.orderNo;

      this.orderStore.setLoading(true);
      if(addState){
        this.removeAll();
        this.add(respDt);
        this.setPage(respDt.orderNo);
      }
      this.orderStore.setLoading(false);
    }
    return respDt;
  }

  async getActivityByRsvTokenId(rsvTokenId : string, channelTag : string){
    let respDt = null;
    respDt = await this.reqGetActivityByRsvTokenId(this.accessToken, channelTag, rsvTokenId);

    if(!(respDt instanceof HttpErrorResponse)){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;

      respDt.forEach(function(order: DetailActivity, order_i: number){
        respDt[order_i].id = order.orderNo;
      }.bind(this));

      this.orderStore.setLoading(true);
      this.removeAll();
      this.add(respDt);
      let currentPageData = this.getCurrentPage();
      if(currentPageData){
        this.setPage(currentPageData.orderNo ? currentPageData.orderNo : respDt[0]?.orderNo);
      }
      else{
        this.setPage(respDt[0]?.orderNo);
      }
      this.orderStore.setLoading(false);
    }

    return respDt;
  }

  async getActivityByActivityGuid(activityGuid: string, addState : boolean, channelTag?: string){
    let respDt = null;
    let result = [];

    respDt = await this.reqGetActivityByActivityGuid(this.accessToken, activityGuid, channelTag);

    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;

      respDt.id = respDt.orderNo;

      this.orderStore.setLoading(true);
      if(addState){
        this.removeAll();
        this.add(respDt);
        this.setPage(respDt.orderNo);
      }
      this.orderStore.setLoading(false);

      result.push(respDt);

      return result;
    }

    return respDt;
  }

  async orderPaymentSafetyCheck(channelTag : string, orderPaymentSafetyCheckReq : OrderPaymentSafetyCheckRequestModel){
    let respDt = null;
    respDt = await this.reqOrderPaymentSafetyCheck(orderPaymentSafetyCheckReq, channelTag);

    if(respDt instanceof HttpErrorResponse){

    }
    else{
      return respDt['body'];
    }
  }

  private async reqGetActivitySummary(accessToken: string, skip: number, take: number, isActive: boolean, isComplete?: boolean, channelTag?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.order.GetActivitySummary,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        skip: skip,
        take: take,
        isActive: isActive,
        isComplete: isComplete != null? isComplete: undefined,
        channelTag: channelTag? channelTag: undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqGetOrderSummary(accessToken: string, payTranId: number, channelTag: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.order.GetOrderSummary,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken? accessToken: undefined,
      },
      queryParams: {
        paytranid: payTranId,
        channelTag: channelTag? channelTag: undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqGetActivityDetail(accessToken: string, activityId: number, channelTag?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.order.GetActivityDetail,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        activityid: activityId,
        channelTag: channelTag? channelTag: undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqGetOrderDataHistory(accessToken: string, orderTranId: number) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.order.GetOrderDataHistory,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        orderTranId: orderTranId,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqCheckoutOrder(accessToken : string, checkoutInfo : CheckoutOrderRequest){
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.order.CheckoutOrder,
      hostPath: environment.hostPath,
      body:{
        channelId : checkoutInfo.channelId,
        paymentId : checkoutInfo.paymentId,
        cCardId : checkoutInfo.cCardId,
        saveCardFlag : checkoutInfo.saveCardFlag,
        isApp : checkoutInfo.isApp ? checkoutInfo.isApp : undefined,
        netTotal : checkoutInfo.netTotal,
        currCode : checkoutInfo.currCode,
        requestUrl : checkoutInfo.requestUrl,
        forceCheckOut: checkoutInfo.forceCheckOut ? checkoutInfo.forceCheckOut : false,
        cartTranIds : checkoutInfo.cartTranIds,
        orderHs : checkoutInfo.orderHs,
        orderTranIds : checkoutInfo.orderTranIds,
        walletCode : checkoutInfo.walletCode,

      },
      headers: {
        accessToken: accessToken ? accessToken : undefined,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    }as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqCancelOrder(accessToken: string, orderTranId: number): Promise<string> {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.order.RequestCancelOrder,
      hostPath: environment.hostPath,
      body: {
        orderTranId: orderTranId
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqAcceptOrder(accessToken: string, orderTranId: number): Promise<string> {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.order.AcceptOrder,
      hostPath: environment.hostPath,
      body: {
        orderTranId: orderTranId
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqRefundOrder(accessToken: string, formData: FormData): Promise<string> {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.order.RequestRefundOrder,
      hostPath: environment.hostPath,
      body: formData,
      headers: {
        accessToken: accessToken,
        contentType: '',
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr, true);

    return respInfo;
  }

  private async reqReceipt(accessToken: string, activityId: number, email: string): Promise<string> {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.order.ReceiptRequest,
      hostPath: environment.hostPath,
      body: {
        activityId: activityId,
        email: email
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqUpdateChatId(accessToken: string, chatId: string, activityId?: number){
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.UpdateChatId,
      hostPath: environment.hostPath,
      queryParams: {
        chatId: chatId,
        activityId: activityId,
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqConfirmPickupOrder(accessToken: string, orderTranId: number): Promise<boolean> {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.order.ConfirmPickupOrder,
      hostPath: environment.hostPath,
      body: {
        orderTranId: orderTranId,
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqUpdateCustomer(accessToken : string, updateCustomerRequest : UpdateCustomerRequest){
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.order.UpdateCustomer,
      hostPath: environment.hostPath,
      body: {
        orderTranId : updateCustomerRequest.orderTranId,
        custName : updateCustomerRequest.custName ? updateCustomerRequest.custName : undefined,
        address: updateCustomerRequest.address ? updateCustomerRequest.address : undefined,
        addressDesc: updateCustomerRequest.addressDesc ? updateCustomerRequest.addressDesc : undefined,
        addUnit: updateCustomerRequest.addUnit ? updateCustomerRequest.addUnit : undefined,
        addCity: updateCustomerRequest.addCity ? updateCustomerRequest.addCity : undefined,
        addPostal: updateCustomerRequest.addPostal ? updateCustomerRequest.addPostal : undefined,
        addState : updateCustomerRequest.addState ? updateCustomerRequest.addState : undefined,
        addCountry: updateCustomerRequest.addCountry ? updateCustomerRequest.addCountry : undefined,
        email: updateCustomerRequest.email ? updateCustomerRequest.email : undefined,
        mobileNo : updateCustomerRequest.mobileNo ? updateCustomerRequest.mobileNo : undefined,
        refNo: updateCustomerRequest.refNo ? updateCustomerRequest.refNo : undefined
      },
      headers: {
        accessToken : accessToken
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetActivityByOrders(accessToken : string, channelTag : string, orderTranIds : number[]){
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.order.GetActivityByOrders,
      hostPath: environment.hostPath,
      queryParams: {
        channelTag : channelTag
      },
      body: {
        orderTranIds
      },
      headers: {
        accessToken : accessToken
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetActivityByRsvTokenId(accessToken : string, channelTag : string, rsvTokenId : string){
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.order.GetActivityByRsvTokenId,
      hostPath: environment.hostPath,
      queryParams: {
        RsvTokenId : rsvTokenId,
        channelTag : channelTag
      },
      headers: {
        accessToken : accessToken
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetActivityByActivityGuid(accessToken : string, activityGuid: string, channelTag?: string){
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.order.GetActivityByActivityGuid,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
        channelTag: channelTag? channelTag: undefined
      },
      queryParams: {
        activityGuid: activityGuid,

      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqOrderPaymentSafetyCheck(orderPaymentSafetyCheckReq : OrderPaymentSafetyCheckRequestModel, channelTag? : string){
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.order.OrderPaymentSafetyCheck,
      hostPath: environment.hostPath,
      headers: {
        channelTag: channelTag? channelTag: undefined
      },
      body: orderPaymentSafetyCheckReq
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isOriBody?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, false, isOriBody).then((dd: any) => dd);
  }

  async qrOrderSummaryPreNavigation(detailActivities : DetailActivity[]){
    if(!detailActivities || detailActivities.length == 0){
      return;
    }

    detailActivities = _.cloneDeep(detailActivities);
    //set each id property with orderTranId as orderNo is all the same thing
    detailActivities.forEach((val, index) => val.id = val.orderNo);
    // add detailActivity into state
    this.removeAll();
    this.orderStore.add(detailActivities);
    // set active entity by orderNo
    let currentPageData = this.getCurrentPage();
    if(currentPageData){
      let detailActivity = detailActivities.find(val => val.orderNo == currentPageData.orderNo);
      let orderIndex = detailActivities.indexOf(detailActivity);
      let currentSlide = orderIndex + 1;

      if(!detailActivity){
        this.removeCurrentPageStored();
      }
      else if(detailActivity && currentSlide != currentPageData.pageNo){
        let currentPageString = JSON.stringify({orderNo : detailActivity.orderNo, pageNo: currentSlide});
        this.saveCurrentPage(currentPageString);
      }

      this.orderStore.setActive(detailActivity ? detailActivity.orderNo : detailActivities[0].orderNo);
    }
    else{
      this.orderStore.setActive(detailActivities[0].orderNo);
    }
    // save detailActivity array into session storage
    let activityDetailString = JSON.stringify(detailActivities);
    this.sessionStorageService.setItem('qrOrderSummary', activityDetailString);
  }

  async revertCutOffOrder(channelTag, orderTranIds: number[]) {
    let respDt = null;
    respDt = await this.reqRevertCutOffOrder(this.accessToken, channelTag, orderTranIds);
    return respDt;
  }

  private async reqRevertCutOffOrder(accessToken: string, channelTag: string, orderTranIds: number[]) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.order.RevertCutOffOrder,
      hostPath: environment.hostPath,
      queryParams: {
        channelTag : channelTag
      },
      body: {
        orderTranIds
      },
      headers: {
        accessToken : accessToken
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  //#region get/set isNavigated property
  setIsNavigated(isNavigate : boolean){
    this.isNavigated = isNavigate;
  }

  getIsNavigated(){
    return this.isNavigated;
  }

  //#endregion

  async checkIfOrdersIsComplete(detailActivities : DetailActivity[]){
    let completedOrderList = detailActivities.filter(order => order.lastActivityType == ActivityType.Order_completed);
    return completedOrderList.length;
  }

  async compareOrderStatus(currentOrders : DetailActivity[], newOrders : DetailActivity[]){
    let index = 0;
    let diffCount = 0;

    if(newOrders){
      if(newOrders.length != currentOrders.length){
        diffCount = 1

        let currentPageData = this.getCurrentPage();
        if(currentPageData){
          let previousPage = newOrders.find(order => order.orderNo == currentPageData.orderNo);
          if(!previousPage){
            this.removeCurrentPageStored();
          }
        }
      }
      else{
        for await(let order of newOrders){
          if((order.lastActivityType != currentOrders[index].lastActivityType) || order.orderData.rowVersion != currentOrders[index].orderData.rowVersion){
            diffCount += 1;
          }
          index += 1;
        }
      }
    }

    return diffCount;
  }

  async addOrderToState(orders : DetailActivity[], multiOrder : boolean, qrOrder : boolean, singleOrder : boolean){
    if(singleOrder){
      this.removeAll();
      this.add(orders);
      this.orderStore.setActive(orders[0]?.orderNo);
    }
    else if(multiOrder){
      this.removeAll();
      this.add(orders);
      let currentPageData = this.getCurrentPage();
      if(currentPageData){
        let detailActivity = orders.find(val => val.orderNo == currentPageData.orderNo);
        let orderIndex = orders.indexOf(detailActivity);
        let currentSlide = orderIndex + 1;

        if(!detailActivity){
          this.removeCurrentPageStored();
        }
        else if(detailActivity && currentSlide != currentPageData.pageNo){
          let currentPageString = JSON.stringify({orderNo : detailActivity.orderNo, pageNo: currentSlide});
          this.saveCurrentPage(currentPageString);
        }

        this.orderStore.setActive(detailActivity ? detailActivity.orderNo : orders[0].orderNo);
      }
      else{
        this.orderStore.setActive(orders[0]?.orderNo);
      }
    }
    else if(qrOrder){
      let activityId = +this.sessionStorageService.getItem("qrActivityId");
      this.sessionStorageService.removeItem("qrActivityId");

      if(activityId){
        let index = orders.findIndex(orders => orders.activityId == activityId);
        if(index != -1){
          if(!this.getCurrentPage()){
            let currentPageString = JSON.stringify({orderNo : orders[index].orderNo, pageNo: index + 1});
            this.saveCurrentPage(currentPageString);
          }
        }
        else{
          this.removeCurrentPageStored();
        }
      }
      this.qrOrderSummaryPreNavigation(orders);
    }
  }
}
