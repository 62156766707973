<app-download-app></app-download-app>

<!-- mobile header -->
<div class="mobile-header hidden-lg">
  <div class="sub-header">
    <app-back-button class="back-btn" [color]="'var(--backbutton)'"></app-back-button>
    <h1 class="heading-14">
      {{ 'otp.complete.profile.1' | translate }}
    </h1>
  </div>
</div>

<!-- content container -->
<div class="p-d-flex profile-container" [ngClass]="{'submit-height': isQueue && !isQueueRegister, 'register-height': (!isQueue) || (isQueue && isQueueRegister)}">
  <div class="content">
    <div class="profile-header">
      <img src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot" />
      <h2 class="heading-11 sub-title" *ngIf="!isQueue">{{'otp.complete.profile.2' | translate}}</h2>
      <h2 class="heading-11 sub-title" *ngIf="isQueue">{{"queue.register.profile.desc.1" | translate}}</h2>
      <h2 class="heading-12 description" *ngIf="!isQueue">{{'otp.complete.profile.3' | translate}}</h2>
      <h2 class="heading-12 description" *ngIf="isQueue">{{"queue.register.profile.desc.2" | translate}}</h2>
    </div>

    <form (ngSubmit)="onSubmit()" [formGroup]="fgroup">
      <!--name-->
      <div class="input-field">
        <label for="name" class="heading-11">{{ 'input.label.19' | translate }}<span class="mandatory">*</span></label>
        <input id="name" type="text" pInputText formControlName="name"
        placeholder="{{ 'input.placeholder.7' | translate }}">
        <div class="checkbox-container" (click)="onTickRegister()" *ngIf="isQueue && canRegister">
          <span class="uncheck" *ngIf="!isQueueRegister"></span>
          <!--  (click)="onTickRegister()" -->
          <i class="oda-check-alt" *ngIf="isQueueRegister"></i>
          <span class="heading-9 check-description">{{"queue.register.profile.button" | translate}}</span>
        </div>
      </div>

      <!--email-->
      <div class="input-field" *ngIf="(!isQueue) || (isQueue && isQueueRegister)">
        <h1 class="heading-11">{{ 'input.label.8' | translate }}</h1>
        <span class="p-float-label">
          <input type="text" pInputText formControlName="email" placeholder="{{ 'input.placeholder.8' | translate }}"
            #passwordvalue />
          <div *ngIf="f.email.errors?.pattern" class="invalid-feeback">
            <i class="oda-alert"></i>
            {{ 'refund.return.email.error.email.invalid' | translate }}
          </div>
          <div *ngIf="displayError" class="invalid-feeback">
            <i class="oda-alert"></i>
            {{ 'UserRegisterConflict_409' | translate : { ErrorField: errorField, ErrorMessage: errorMessage } }}
          </div>
        </span>
      </div>

      <!--dob-->
      <div class="input-field" *ngIf="(!isQueue) || (isQueue && isQueueRegister)">
        <h1 class="heading-11">{{ 'login.register.form.dob' | translate }}</h1>
        <span class="p-float-label">
          <p-calendar
            formControlName="dob"
            dateFormat="dd/mm/yy"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'1900:' + maxYearRange"
            [maxDate]="maxDate"
            [touchUI]="isMobileView"
            [readonlyInput]="true"
            inputId="calendar"
            styleClass="CalenderStyle"
            placeholder="{{ 'input.placeholder.15' | translate }}">
          </p-calendar>
        </span>
      </div>

      <button type="submit" class="submit-btn hidden-sm" [ngClass]="{'disabled-button': !fgroup.valid }">
        {{('button.submit' | translate)}}
      </button>

      <!-- terms and condition -->
      <p class="term-and-condition hidden-sm" *ngIf="isQueue && isQueueRegister">
        {{"login.term.and.privacy.policy.1" | translate}}
        <span class="important-terms" (click)="onNavigate('tNc')">{{"login.term.and.privacy.policy.2" | translate}}</span>
        {{"login.term.and.privacy.policy.3" | translate}}
        <span class="important-terms" (click)="onNavigate('privacyPolicy')">{{"login.term.and.privacy.policy.4" | translate}}</span>
      </p>
    </form>
  </div>
</div>

<!-- mobile footer -->
<div class="mobile-footer hidden-lg">
  <button type="button" class="submit-btn" (click)="onSubmit()" [ngClass]="{'disabled-button': !fgroup.valid }">
    {{('button.submit' | translate)}}
  </button>

  <p class="term-and-condition" *ngIf="isQueue && isQueueRegister">
    {{"login.term.and.privacy.policy.1" | translate}}
    <span class="important-terms" (click)="onNavigate('tNc')">{{"login.term.and.privacy.policy.2" | translate}}</span>
    {{"login.term.and.privacy.policy.3" | translate}}
    <span class="important-terms" (click)="onNavigate('privacyPolicy')">{{"login.term.and.privacy.policy.4" | translate}}</span>
  </p>
</div>

<div class="register-failed-popup">
  <p-dialog header=" " [(visible)]="displayRegisterFailedMsg" [position]="dialogPosition" [dismissableMask]="isMobileView"
  [baseZIndex]="10000" [modal]="true" [closable]='true'>
    <app-register-failed
      (tryAgain)="closeFailedPopup()"
      (proceed)="proceedToQueue()"
      *ngIf="displayRegisterFailedMsg">
    </app-register-failed>
  </p-dialog>
</div>
