<div class="address-container">
  <div *ngIf="orderType !== orderTypeFlag.DineIn || !tableNoReq">
    <h1 class="heading-7 address-header hidden-sm">{{'address.form.address' | translate }}</h1>
    <div [ngClass]="{'sticky-header': isMobileView}">
      <div class="hidden-lg mobile-address-header">
        <i class="oda-chevron-left" (click)="onCloseAddress()"></i>
        <h1 class="heading-7 ma-header">{{'address.heading_1' | translate }}</h1>
      </div>
      <!--search location bar-->
      <div class="addressList-search-bar">
        <app-address-search (outputSelectedAddress)="selectAddress($event)" [searchValue]="currentAddress"></app-address-search>
        <!--error msg: out of coverage-->
        <span class="out-of-coverage-error-msg" *ngIf="outOfService">
          <i class="oda-alert"></i>
          <h1 class="error-text">{{"address.out.of.coverage" | translate}}</h1>
        </span>
      </div>
    </div>
    <!---scrolling: recent address and saved address lists-->
    <div class="scroll-list"  [ngClass]="{ 'add-margin': outOfService && isMobileView }">
      <div class="recent-content" *ngIf="recentAddress !== null" (click)="selectRecentAddress(recentAddress)">
        <div id="bgs">
          <div class="content">
            <div class="recent-address">
              <h1 class="heading-11">{{'location.category.recent' | translate}}</h1>
              <h2 class="heading-9">{{recentAddress?.fullAddress}}</h2>
            </div>
            <button class="save-btn" (click)="addNewAddressEmit(true)">{{'address.form.button.save' | translate}}</button>
          </div>
        </div>
      </div>

      <app-address-list *ngIf="isUserLogin" [savedAddress]="savedAddress" [updateAddressSuccess]="updateAddressSuccess"
                        [isCartPage]="isCartPage" [cartGuid]="cartGuid" [customerId]="+customerId"
                        (displayAddAddressForm)="addNewAddressEmit(false)" (editSelectedAddress)="editAddressEmit($event)"
                        (deleteSelectedAddress)="deleteAddressEmit($event)" (outputSelectedAddress)="selectCloseAddress($event)">
        </app-address-list>
    </div>
  </div>

  <!--dine in: table no-->
  <div *ngIf="orderType === orderTypeFlag.DineIn && tableNoReq">
    <h1 class="heading-7">{{ 'dinein.location.label' | translate }}</h1>
    <!--scan location bar-->
    <div class="addressList-search-bar">
      <app-location-search (outputSelectedLocation)="selectCloseLocation($event)"></app-location-search>
    </div>
  </div>

</div>
