import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CXMCustomerAddressResponse } from 'src/app/core/models/CXMCustomerAddressResponse';
import { UserService } from 'src/app/core/user/user.service';
import { ChannelService } from 'src/app/home/channel/channel.service';

@Component({
  selector: 'app-manage-address',
  templateUrl: './manage-address.component.html',
  styleUrls: ['./manage-address.component.scss']
})
export class ManageAddressComponent implements OnInit {
  savedAddress: CXMCustomerAddressResponse[] = [];
  showSuccessUpdate: boolean = false;

  constructor(
    private userService: UserService,
    private channelService: ChannelService,
    private router: Router,
  ) { }

  async ngOnInit() {
    await this.channelService.getChannelDataCheck();
    this.savedAddress = await this.userService.getCustomerAddress(this.channelService.getChannelId());

    if (history.state.addressUpdated) {
      this.showSuccessUpdate = true;
    } else {
      this.showSuccessUpdate = false;
    }
  }

  editSelectedAddress(address: CXMCustomerAddressResponse) {
    this.userService.setEditAddress(address);
    this.router.navigateByUrl('/account/address/edit-address');
  }

  async deleteSelectedAddress(addressId: number) {
    await this.userService.deleteCustomerAddress(addressId, this.channelService.getChannelId());
    await this.getCustomerAddress();
  }

  async getCustomerAddress() {
    this.savedAddress = await this.userService.getCustomerAddress(this.channelService.getChannelId());
  }
}
