<app-download-app class="hidden-sm"></app-download-app>

<div class="wrapper">
  <div class="mobile-title-container hidden-lg">
    <div class="back-button-container">
      <app-back-button
        [manualBackEvent]="true"
        [color]="'var(--backbutton)'"
        [fontSize]="'15px'"
        (onManualBackEvent)="backToPreviousPage()">
      </app-back-button>
    </div>
    <h2 class="mobile-title ">{{"qr.account.detected.title.2" | translate}}</h2>
  </div>


  <div class="content-container p-d-flex" [ngClass]="{'continue-button-hidden': !showContinueAsGuest, 'guest-container-height': showContinueAsGuest}">
    <!-- mascot container -->
    <div class="mascot-container">
      <img src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot"/>
    </div>

    <!-- title -->
    <h3 class="title-description heading-11" *ngIf="isAccountDetected">{{"account.detected.title.1" | translate}}</h3>
    <h3 class="login-register-label heading-11" *ngIf="isAreYouInterested">{{"qr.phone.submit.title.4" | translate}}</h3>

    <!-- description -->
    <p class="mobile-detected-description heading-9" *ngIf="isAccountDetected">{{"account.detected.desc.1" | translate}}</p>
    <p class="login-register-description heading-9" *ngIf="isAreYouInterested">{{"qr.phone.submit.desc.3" | translate}}</p>

    <!-- login and register button for desktop view -->
    <button class="login-action-button hidden-sm" (click)="onClickLogin()" *ngIf="isAccountDetected">{{"button.login" | translate}}</button>
    <button class="login-register-action-button hidden-sm" (click)="onClickRegister()" *ngIf="isAreYouInterested">{{"login.register.form.header.title.register" | translate}}</button>
  </div>

  <!-- footer button container -->
  <div class="guest-action-button-container">
    <!-- login and register button for mobile view -->
    <button class="login-action-button hidden-lg" (click)="onClickLogin()" *ngIf="isAccountDetected">{{"button.login" | translate}}</button>
    <button class="login-register-action-button hidden-lg" (click)="onClickRegister()" *ngIf="isAreYouInterested">{{"login.register.form.header.title.register" | translate}}</button>
    <!-- continue as guest button -->
    <button class="guest-action-button heading-11" (click)="onClickContinueAsGuest()" *ngIf="showContinueAsGuest">{{"button.continue.as.guest" | translate}}</button>
  </div>
</div>
