<div class="overall-container">
  <app-loader></app-loader>

  <app-header (onOrderTypeChanged)="onOrderTypeChanged($event)" (onLocation)="onClickLocation()"
    (onOrderTypeLocation)="onClickOrderTypeLocation()" (onChangeChannel)="onChangeChannel($event)"
    [curRouter]="curRouter" [showMobileHeader]="showMobileHeader" *ngIf="showHeader" flexLayout="column">
  </app-header>

  <div flexLayout="row" fxFlex [style.background-color]="curRouter === 'home' && !isMobileView ? 'var(--desktop-background-primary)' : 'var(--background-primary)'">
    <div class="content" >
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer [showFooter]="showFooter" [showMobileBottomNav]="showMobileBottomNav"
    [isFinishedLoading]="isFinishedLoading" flexLayout="column"></app-footer>


  <!-- modal -->
  <!--mobile view: choose orderType and location-->
  <div class="mobile-orderType-location-dialog" *ngIf="isMobileView && !displayAddressDialog">
    <p-dialog *ngIf="displayOrderTypeAdressDialog" header="" [(visible)]="displayOrderTypeAdressDialog"
      [position]="dialogPosition" [modal]="true" [dismissableMask]="false" [blockScroll]="true">
      <app-order-type (onOrderTypeChanged)="mobileOrderTypeChanged($event)"></app-order-type>
      <hr>
      <div class="mobile-orderType-location-container">
        <div *ngIf="channelOrderType !== orderTypeFlag.DineIn || !shareTable">
          <h1 class="mobile-orderType-location-header">{{'address.form.address' | translate}}</h1>
          <div class="selectedAdreess" (click)="onClickLocation()">
            <i class="oda-pin"></i>
            <h1>{{fulladdress}}</h1>
            <i class="oda-chevron-right"></i>
          </div>
        </div>
        <!--dine in: table no-->
        <div *ngIf="channelOrderType === orderTypeFlag.DineIn && shareTable">
          <h1 class="mobile-orderType-location-header">{{ 'dinein.location.label' | translate }}</h1>
          <!--scan location bar-->
          <app-location-search></app-location-search>
        </div>
        <!--close btn-->
        <button class="orderTypeLocation-done-btn" (click)="mobileOrderTypeDoneAction()">
          {{'button.done' | translate}}
        </button>
      </div>
    </p-dialog>
  </div>

  <p-dialog *ngIf="displayAddressDialog" header="" [(visible)]="displayAddressDialog" [modal]="true"
    [dismissableMask]="true" [blockScroll]="true" styleClass="{{dialogClassName}}">
    <div class="col-12 custom-close-button" style="text-align: right; padding: 0px;">
      <button class="dialog-close-btn" (click)="closeDialog()">
        <span><i class="oda-times" style="font-size: 15px; color: lightgray;"></i></span>
      </button>
    </div>
    <div class="home-address-dialog-content">
      <app-address *ngIf="displayAddress" [savedAddress]="savedAddress" [updateAddressSuccess]="updateAddressSuccess"
        [orderType]="channelOrderType" [tableNoReq]="shareTable" (displayAddAddressForm)="displayAddAddressForm($event)"
        (editSelectedAddress)="editSelectedAddress($event)" (deleteSelectedAddress)="deleteSelectedAddress($event)"
        (closeDialog)="closeDialog()" (onQuitAddress)="this.displayAddressDialog = false">
      </app-address>
    </div>
    <app-address-form *ngIf="displayAddAddress" (onSubmitAddAddress)="onSubmitAddAddress($event)"
      (onBackPreviousPage)="onClickCloseDialog()" (onUpdateAddress)="onUpdateAddress($event)"
      [editSelectedAddress]="editUserAddress" [isAddNewAddressDialog]="true" [savedNewAddress]="localSavedNewAddress">
    </app-address-form>
  </p-dialog>
  <app-popup-message></app-popup-message>
</div>

<div class="qr-close-message">
  <p-dialog header=" " [(visible)]="displayQuitQrDineMessage" [position]="dialogPosition"
    [dismissableMask]="dismissable" [style]="{width: '520px'}" [baseZIndex]="10000" [modal]="true" [closable]='true'
    *ngIf="displayQuitQrDineMessage">
    <app-qr-quit-message (clickNo)="closeQuitQrDialog()" (clickYes)="quitQrDineInMode()" [channelId]="channelId"></app-qr-quit-message>
  </p-dialog>
</div>

<app-toast></app-toast>

<div class="session-expire-dialog">
  <p-dialog [(visible)]="displaySessionExpiredMessage" [position]="dialogPosition" [dismissableMask]="dismissable"
    [style]="{width: '400px', height: '300px'}" [baseZIndex]="10000" [modal]="true" [closable]='true'
    (onHide)="navigateToLogin()">

    <i class="oda-times close-icon hidden-sm" (click)="closeSessionExpiredPopup()"></i>

    <app-login-message (navigateToLogin)="closeSessionExpiredPopup()" [sessionExpired]="sessionExpired" [channelId]="channelId">
    </app-login-message>

  </p-dialog>
</div>

<div class="scanner-dialog">
  <p-dialog header=" " [(visible)]="displayScanner" [baseZIndex]="999999999999" *ngIf="displayScanner">
    <app-qr-scanner [isGeneralScan]="qrScannerData.isGeneralScan" [showHeaderDesc]="qrScannerData.headerDesc" [channelId]="channelId"
    (closeDialog)="displayScanner = false"></app-qr-scanner>
  </p-dialog>
</div>

<div class="queue-quit-message">
  <p-dialog header=" " [(visible)]="displayQuitQueueMessage" [position]="dialogPosition" [dismissableMask]="dismissable"
    [baseZIndex]="10000" [modal]="true" [closable]='false'>
    <app-quit-queue-message (onCancel)="closeQuitQueueDialog()" (backToOdaring)="quitQueueMode()" [channelId]="channelId"
      *ngIf="displayQuitQueueMessage">
    </app-quit-queue-message>
  </p-dialog>
</div>

<div class="qr-dialog">
  <p-dialog header="" [(visible)]="displayQrPopup" [blockScroll]="true" [modal]="true" [dismissableMask]="true"
    styleClass="custom-qr-dialog" *ngIf="displayQrPopup">
    <app-user-qr-code (closeDialog)="displayQrPopup = false"></app-user-qr-code>
  </p-dialog>
</div>
