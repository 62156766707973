<div class="reset-mobile-otp-container">
    <h1 class="hidden-sm">{{ "otp.header.des.2" | translate }}</h1>
    <p class="hidden-sm">{{ "otp.subtitle.des.2" | translate }}</p>
    <div class="header hidden-lg">
        <app-back-button [color]="'var(--backbutton)'"></app-back-button>
        <h1>{{ "otp.forgotPassword.header.des.2" | translate }}</h1>
    </div>
    <div class="content-container">
        <div class="mascot hidden-lg">
            <img src="assets/mascot/Mascot_phone-success@2x.webp" alt="odaring mascot">
            <p>{{ "otp.description" | translate }}</p>
            <div class="phone">
                <p *ngIf="phoneNo">+{{ phoneNo }}</p>
                <i class="duotone oda-d-edit"></i>
            </div>
        </div>
        <form [formGroup]="resetMobileForm" (ngSubmit)="onSubmit()" #form="ngForm" id="resetMobileForm">
            <div class="otp-container" formArrayName="otp" #otpArray>
                <div class="prefix" *ngIf="prefix">{{ prefix }}</div>
                <input type="text" pattern="[0-9]+" id="otpInput-{{i}}" autocomplete="off"
                    [ngClass]="{'active': invalidOtpErr}"
                    *ngFor="let otpInput of otpArr.controls; index as i" [formControlName]="i"
                    (input)="onOtpInput($event, i)" (keydown)="onOtpKeydown($event, i)" (click)="onOtpFocus($event, i)">
            </div>
            <div class="msg-container">
                <p class="error" *ngIf="invalidOtpErr"><i class="oda-alert"></i>{{ "otp.input.error" | translate }}</p>
                <p class="timer" *ngIf="showTimer">{{ "otp.resend.otp.in" | translate }}<app-timer [timeInSec]="timeInSec" (onCallback)="onTimerCallback()"></app-timer></p>
                <div class="resend" *ngIf="showResend">
                    <div class="no-receive">
                        <span>{{ "otp.resend.message.1" | translate }}</span>
                        <div class="resend-via">
                            <button type="button" class="btn-via" (click)="onSendOtp()">{{ "otp.resend.message.2" | translate }}</button>
                            <span class="or-text" *ngIf="verifiedEmail">{{ 'opt.form.des.1' | translate }}</span>
                            <button type="button" class="btn-via" (click)="onSendOtp(true)" *ngIf="verifiedEmail">{{ "otp.resend.message.3" | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-container">
                <button type="submit" id="btn-submit">{{ "button.verify" | translate }}</button>
            </div>
        </form>
    </div>
</div>
