
<div class="p-d-flex login_container">

  <div class="login-slider hidden-sm">
    <app-slider></app-slider>
  </div>

  <div class="login-content">

    <div class="hidden-sm">
      <h1 class="p-d-flex p-jc-center login-form-header">{{ 'login.register.form.header.title' | translate }}</h1>
      <h2 class="p-d-flex p-jc-center login-form-header-desc">{{'login.register.form.header.title.4' | translate}}</h2>
    </div>

    <div class="hidden-lg mobile-header">
      <div class="sub-header">
        <app-back-button class="back-btn" [color]="'var(--backbutton)'" [manualBackEvent]="true" (onManualBackEvent)="onClickBack()"></app-back-button>
        <h1 class="heading-14">{{ 'login.register.form.header.title.2' | translate }}</h1>
      </div>
      <img src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot"/>
      <h2>{{'login.register.form.header.title.4' | translate}}</h2>
    </div>

    <!-- form -->
    <form (ngSubmit)="onSubmit()" [formGroup]="fgroup">
      <div class="p-float-label">
        <h1 class="login-form-label p-mb-2">{{ 'input.label.1' | translate }}</h1>
        <ngx-intl-tel-input [cssClass]=""
                            [enableAutoCountrySelect]="telInpSetting.enableAutoCountrySelect"
                            [customPlaceholder]="telInpSetting.customPlaceholder | translate" [enablePlaceholder]="telInpSetting.enablePlaceholder"
                            [searchCountryFlag]="telInpSetting.searchCountryFlag" [searchCountryField]="telInpSetting.searchCountryField"
                            [selectFirstCountry]="telInpSetting.selectFirstCountry"
                            [phoneValidation]="telInpSetting.phoneValidation" [inputId]="'phoneNo'"
                            [separateDialCode]="telInpSetting.separateDialCode" [preferredCountries]="telInpSetting.preferredCountries"
                            [selectedCountryISO]="telInpSetting.selectedCountryISO" name="phone" formControlName="phoneNo" #phone_number
                            autofocus
                            (keyup.enter)="onSubmit()"
                            #mobilefocus>
        </ngx-intl-tel-input>
        <button type="reset" label="" class="login-btn-clear" *ngIf="phone_number.value && phone_number.value.length > 0">
          <i class="oda-times"></i>
        </button>
      </div>

      <div class="invalid-feeback" *ngIf="isInvalid">
        <i class="oda-alert"></i>
        {{ 'login.form.mobile.number.invalid' | translate }}
      </div>

      <button type="submit" class="login-btn-cta" [ngClass]="{'login-btn-cta-disabled': !phone_number.value }"
      [disabled]="!phone_number.value">
        {{ 'button.continue' | translate }}
      </button>

      <div class="agree-tnc">
        <span>{{ 'login.term.and.privacy.policy.1' | translate }}</span>
        <span class="link-btn" (click)="onNavigate('tNc')">{{ 'login.term.and.privacy.policy.2' | translate }}</span>
        <span>{{ 'login.term.and.privacy.policy.3' | translate }}</span>
        <span class="link-btn" (click)="onNavigate('privacyPolicy')">{{ 'login.term.and.privacy.policy.4' | translate }}</span>
      </div>

      <div *ngIf="showContGuest" class="p-d-flex p-jc-center" style="padding-bottom: 20px;">
        <div class="login-btn-guest" (click)="continueAsGuest()">{{'button.continue.as.guest' | translate}}</div>
      </div>
    </form>
  </div>
</div>
